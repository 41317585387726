import React from 'react';
import LoadingMask from "react-loadingmask";
import SideBar from '../../shared/pageComponent/SideBar';
import HeaderFile from '../../shared/pageComponent/HeaderFile';
import './DashboardStructure.scss';
import { ToastContainer, Zoom } from 'react-toastify';
import { Numbers } from '../../../constants/constant';

const DashboardStructure = ({ title, children, history, ...props }) => {
  return (
    <LoadingMask loading={props.loading} text={"loading..."}>
      <div className="main-container">
        <SideBar />
        <div className="content-box">
          <ToastContainer
            position="top-center"
            autoClose={Numbers.twoThou}
            transition={Zoom}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <HeaderFile title={title} history={history} />
          {children}
        </div>
      </div>
    </LoadingMask>
  )
}
export default DashboardStructure;
