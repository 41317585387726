import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import * as Solid from "@heroicons/react/24/solid";
import { AudioPlayerProvider } from "react-use-audio-player";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  getSongContestList,
  addedFeaturedSongs,
  removedFeaturedSongs,
  enableSong,
  deleteSongs,
} from "../../api/song-contest";
import { PlayBackMusic } from "./playback-music";
import { SongsFeatured } from "./songs-featured";
import { Songs } from "./songs";
import "./songContest.scss";

function SongContest(props) {
  const interval = useRef(null);

  const [loadingClear, setLoadingClear] = useState(false);
  const [loadingAdded, setLoadingAdded] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEnable, setLoadingEnable] = useState(false);
  const [loadingStart, setLoadingStart] = useState(false);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [songs, setSongs] = useState([]);
  const [songsFeatured, setSongsFeatured] = useState([]);
  const [current, setCurrent] = useState(-1);
  const [featuredCurrent, setFeaturedCurrent] = useState(-1);
  const [select, setSelect] = useState(false);
  const [songsSelected, setSongsSelected] = useState([]);
  const [elapsedTime, setElapsedTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const playbackFeatured = useMemo(
    () => songsFeatured[featuredCurrent],
    [songsFeatured, featuredCurrent]
  );
  const songsFeaturedEnable = useMemo(() => {
    const activeSongs = songsFeatured.filter((e) => e.is_active === true);
    if (activeSongs.length > 0) {
      return true;
    }
    return false;
  }, [songsFeatured]);

  const getSongSubmissions = useCallback(
    async (clear) => {
      try {
        props.setLoading(true);
        if (clear) {
          setSongs([]);
        }
        const songs = await props.getSongContestList();
        const songsFeatured = await props.getSongContestList(true);
        setSongs(songs.data);
        setSongsFeatured(songsFeatured.data);
        if (songsFeatured.data.length > 0) {
          const currentDate = moment(
            new Date(songsFeatured.data[0].updatedAt)
          );
          clearInterval(interval.current);
          interval.current = setInterval(() => {
            const future = moment(new Date());
            const time = moment(future.diff(currentDate)).utc();
            setElapsedTime({
              days: time.get("D") - 1,
              hours: time.get("h"),
              minutes: time.get("m"),
              seconds: time.get("s"),
            });
          }, 1000);
        } else {
          clearInterval(interval.current);
        }
      } catch (error) {
        toast("Failed to get songs contest", { type: "error" });
      } finally {
        props.setLoading(false);
      }
    },
    [props]
  );

  useEffect(() => {
    props.setTitlePage("Song Contest");
    getSongSubmissions();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleCurrent = (index) => {
    if (featuredCurrent !== -1) {
      setFeaturedCurrent(-1);
    }
    setCurrent((prev) => {
      if (prev === index) {
        return -1;
      }
      return index;
    });
  };

  const handleFeatureCurrent = (index) => {
    if (current !== -1) {
      setCurrent(-1);
    }
    setFeaturedCurrent((prev) => {
      if (prev === index) {
        return -1;
      }
      return index;
    });
  };

  const handleToogleSelect = () => {
    setSelect(!select);
  };

  const handleSelectFeatureSong = (id) => {
    const exist = songsSelected.includes(id);
    if (exist) {
      const index = songsSelected.findIndex((topId) => topId === id);
      songsSelected.splice(index, 1);
      setSongsSelected([...songsSelected]);
    } else {
      if (songsSelected.length === 10) {
        return toast("You are only allowed to choose 10 songs", {
          type: "info",
        });
      }
      setSongsSelected([...songsSelected, id]);
    }
  };

  const handleClearSongs = async () => {
    confirmAlert({
      title: "Confirm to clear featured songs",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              clearInterval(interval.current);
              setLoadingClear(true);
              const songsId = songsFeatured.map((e) => e.id);
              const response = await props.removedFeaturedSongs(songsId);
              await getSongSubmissions();
              toast(response.msg, { type: "success" });
            } catch (error) {
              toast("Failed to clear songs", { type: "error" });
            } finally {
              setLoadingClear(false);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleAddFeatureSongs = async () => {
    try {
      setLoadingAdded(true);
      const response = await props.addedFeaturedSongs(songsSelected);
      setSelect(false);
      setSongsSelected([]);
      await getSongSubmissions();
      toast(response.msg, { type: "success" });
    } catch (error) {
      toast("Failed to added featured songs", { type: "error" });
    } finally {
      setLoadingAdded(false);
    }
  };

  const handleDeleteSongs = async () => {
    confirmAlert({
      title: "Confirm to delete selected songs",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              setLoadingDelete(true);
              const response = await props.deleteSongs(songsSelected);
              setSelect(false);
              setSongsSelected([]);
              await getSongSubmissions(true);
              toast(response.msg, { type: "success" });
            } catch (error) {
              toast("Failed to delete songs", { type: "error" });
            } finally {
              setLoadingDelete(false);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleEnableSong = async () => {
    try {
      setLoadingEnable(true);
      const response = await props.enableSong(
        playbackFeatured.id,
        !playbackFeatured.is_active
      );
      await getSongSubmissions();
      toast(response.msg, { type: "success" });
    } catch (error) {
      toast("Failed to enable song", { type: "error" });
    } finally {
      setLoadingEnable(false);
    }
  };

  const handleEndVoting = async () => {
    try {
      setLoadingEnd(true);
      for await (let song of songsFeatured) {
        await props.enableSong(song.id, false);
      }
      await getSongSubmissions();
      toast("Voting is end", { type: "success" });
    } catch (error) {
      toast("Failed to end voting", { type: "error" });
    } finally {
      setLoadingEnd(false);
    }
  };

  const handleStartVoting = async () => {
    try {
      setLoadingStart(true);
      for await (let song of songsFeatured) {
        await props.enableSong(song.id, true);
      }
      await getSongSubmissions();
      toast("Voting is start", { type: "success" });
    } catch (error) {
      toast("Failed to start voting", { type: "error" });
    } finally {
      setLoadingStart(false);
    }
  };

  return (
    <div className="main-scroll">
      <div className="header-song">
        <h3 className="title" onClick={handleToogleSelect}>
          Contest Submission
        </h3>
        <button
          disabled={
            loadingEnd || songsFeatured.length === 0 || !songsFeaturedEnable
          }
          className="button-feature-song end"
          onClick={handleEndVoting}
        >
          {loadingEnd ? <>Ending...</> : <>End Voting</>}
        </button>
        <button
          disabled={loadingStart || songsFeatured.length === 0}
          className="button-feature-song start"
          onClick={handleStartVoting}
        >
          {loadingStart ? <>Starting...</> : <>Start Voting</>}
        </button>
        <button
          disabled={loadingClear || songsFeatured.length === 0}
          className="button-feature-song"
          onClick={handleClearSongs}
        >
          {loadingClear ? <>Cleaning...</> : <>Clear Songs</>}
        </button>
      </div>
      <div className="timer-content">
        <span className="title">Elapsed Time</span>
        <div className="timer">
          <span className="time-number">{elapsedTime.days} d</span>
          <span className="time-number">{elapsedTime.hours} h</span>
          <span className="time-number">{elapsedTime.minutes} m</span>
          <span className="time-number">{elapsedTime.seconds} s</span>
        </div>
      </div>
      {featuredCurrent !== -1 && (
        <button
          disabled={loadingEnable}
          className={`active-vote ${
            playbackFeatured.is_active && "active"
          }`}
          onClick={handleEnableSong}
        >
          <Solid.HeartIcon className="heart-icon" />
          <span className="text">
            {loadingEnable ? (
              <>Updating...</>
            ) : playbackFeatured.is_active ? (
              <>End Voting ({playbackFeatured.media.title})</>
            ) : (
              <>Start Voting ({playbackFeatured.media.title})</>
            )}
          </span>
        </button>
      )}
      <SongsFeatured
        songsFeatured={songsFeatured}
        featuredCurrent={featuredCurrent}
        handleFeatureCurrent={handleFeatureCurrent}
      />
      <div className="header-song space">
        <div className="group-song">
          <h3 className="title">Song Submissions</h3>
          <div className="song-check">
            <div
              className={`check ${select && "active"}`}
              onClick={handleToogleSelect}
            >
              <Solid.CheckIcon className="check-icon" />
            </div>
            <h3 className="text" onClick={handleToogleSelect}>
              Select song for the contest / delete
            </h3>
          </div>
        </div>
        {songsSelected.length > 0 && (
          <div className="group-button-feature-song">
            <button
              disabled={loadingAdded}
              className="button-feature-song"
              onClick={handleAddFeatureSongs}
            >
              {loadingAdded ? <>Adding...</> : <>Add to song contest</>}
            </button>
            <button
              disabled={loadingDelete}
              className="button-feature-song end"
              onClick={handleDeleteSongs}
            >
              {loadingDelete ? <>Deleting songs...</> : <>Delete songs</>}
            </button>
          </div>
        )}
      </div>
      <Songs
        songs={songs}
        songsSelected={songsSelected}
        select={select}
        current={current}
        handleSelectFeatureSong={handleSelectFeatureSong}
        handleCurrent={handleCurrent}
      />
      {current !== -1 && (
        <AudioPlayerProvider>
          <PlayBackMusic
            songs={songs}
            current={current}
            setCurrent={setCurrent}
          />
        </AudioPlayerProvider>
      )}
      {featuredCurrent !== -1 && (
        <AudioPlayerProvider>
          <PlayBackMusic
            songs={songsFeatured}
            current={featuredCurrent}
            setCurrent={setFeaturedCurrent}
          />
        </AudioPlayerProvider>
      )}
    </div>
  );
}

const mapStateToProps = (store) => {
  return {
    admin: store.user,
  };
};

export default connect(mapStateToProps, {
  getSongContestList,
  addedFeaturedSongs,
  removedFeaturedSongs,
  enableSong,
  deleteSongs,
})(withRouter(SongContest));
