import React from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { getSellParlours, updateSellParlour, deleteSchedulerParlour } from '../../api/scheduler-parlour';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';

function TableSellParlours(props) {
  const [dataTable, setDataTable] = React.useState({
    data: [],
    pagination: {
      totalRecords: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
    }
  });
  const [form, setForm] = React.useState({
    search: '',
    author: '',
    is_top: '',
  });
  const handleChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  };
  const handleSubmit = async (e) => {
    if (e && e.preventDefault) e.preventDefault();
    await getSellParlours();
  }
  const handleEnderInput = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }
  const handleChangeShowTop = async (row) => {
    try {
      props.setLoading(true);
      await props.updateSellParlour(row.id, { is_top: !row.is_top });
      await getSellParlours();
    } catch (error) {
      console.log(error);
    } finally {
      props.setLoading(false);
    }
  };
  const handleDeleteParlour = async (row) => {
    if (window.confirm(`Are you sure you want to delete this parlour "${row.title}"?`)) {
      try {
        props.setLoading(true);
        await props.deleteSchedulerParlour(row.scheduler_parlour.id);
        await getSellParlours();
        toast('Parlour deleted successfully', { type: "success", autoClose: 1000 * 10 });
      } catch (error) {
        const { message } = error.request && error.request.response ? JSON.parse(error.request.response) : {};
        if (message) {
          toast(message, { type: "error", autoClose: 1000 * 10 });
        } else {
          toast('Failed to delete parlour', { type: "error", autoClose: 1000 * 10 });
        }
      } finally {
        props.setLoading(false);
      }
    }
  };
  const getSellParlours = async (page = 1) => {
    try {
      props.setLoading(true);
      const res = await props.getSellParlours({
        ...form, page, pageSize: dataTable.pagination.pageSize
      });
      setDataTable({
        ...dataTable,
        ...res,
      });
    } catch (error) {
      console.log(error);
    } finally {
      props.setLoading(false);
    }
  };
  React.useEffect(() => {
    getSellParlours();
  }, [form.is_top]);

  return (
    <>
      <Form className='card' onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4">
              <FormGroup>
                <Label for="search">Search</Label>
                <Input className='form-control-sm' type="text" autoFocus name="search" value={form.search} id="search" placeholder="Enter search parlour" onChange={handleChange} onKeyDown={handleEnderInput} />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup>
                <Label for="author">Author</Label>
                <Input className='form-control-sm' type="text" name="author" value={form.author} id="author" placeholder="Enter name author" onChange={handleChange} onKeyDown={handleEnderInput} />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup>
                <Label for="is_top">Parlours</Label>
                <select name="is_top" className='form-control form-control-sm' id="is_top" value={form.is_top} onChange={handleChange}> 
                  <option value="">All</option>
                  <option value="true">Top</option>
                  <option value="false">Not Top</option>
                </select>
              </FormGroup>
            </div>
          </div>
        </div>
      </Form>
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Author</th>
              <th>Title</th>
              <th>Description</th>
              <th>Price</th>
              <th>Start Date</th>
              <th>Top Parlour</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              dataTable.data.map((row, index) => (
                <tr key={`tr-${row.id}`}>
                  <th style={{verticalAlign: 'middle'}}>
                    <img src={row.scheduler_parlour.poster_url} width={100} className="img-thumbnail" alt={row.title} loading="lazy" />
                  </th>
                  <td style={{verticalAlign: 'middle'}}>{row.user.name}</td>
                  <td style={{verticalAlign: 'middle'}}>{row.title}</td>
                  <td style={{verticalAlign: 'middle'}}>{row.description}</td>
                  <td style={{verticalAlign: 'middle'}} className='text-center'>
                    {
                      row.status ? `$${row.price}` : ''
                    }
                  </td>
                  <td style={{verticalAlign: 'middle'}}>
                    { new Date(row.scheduler_parlour.start_date).toLocaleString() }
                  </td>
                  <td className='text-center' style={{verticalAlign: 'middle'}}>
                    <input type="checkbox" checked={row.is_top} onChange={() => handleChangeShowTop(row)} value={row.is_top} name={`is_top_${row.id}`} id={`is_top_${row.id}`} />
                  </td>
                  <td style={{verticalAlign: 'middle'}}>
                    {
                      row.video_url 
                        ? (<a href={row.video_url} target="_blank" rel="noopener noreferrer" className='text-success' style={{ cursor: 'pointer'}}>Download</a>) 
                        : 'No video'
                    }
                    &nbsp;|&nbsp;
                    <a href='javascript:void(0)' className='text-danger' onClick={() => handleDeleteParlour(row)}>Delete</a>
                    &nbsp;|&nbsp;
                    <Link style={{ cursor: 'pointer'}} to={`/sell-parlour/detail/${row.id}`} className='text-primary'>Report</Link>
                  </td>
                </tr>
              ))
            }
            {
              dataTable.data.length === 0 && (
                <tr>
                  <td className='p-5 text-center' colSpan={10}>No record found.</td>
                </tr>
              )
            }
          </tbody>
        </table>
        <Pagination
          activePage={dataTable.pagination.currentPage}
          itemsCountPerPage={dataTable.pagination.pageSize}
          totalItemsCount={dataTable.pagination.totalRecords}
          pageRangeDisplayed={5}
          onChange={getSellParlours}
        />
      </div>
    </>
  )
}

export default connect(null, {
  getSellParlours,
  updateSellParlour,
  deleteSchedulerParlour,
})(withRouter(TableSellParlours));