import React, { useState } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import ModalBox from '../../shared/pageComponent/ModalBox';
import ChangePassword from '../../../containers/dashboard/ChangePassword';
import Notification from './NotificationBar';

const HeaderFile = ({ title, history }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggleModal = () => setModalOpen(prevState => !prevState);

  const logout = () => {
    localStorage.removeItem('token');
    if (history) {
      history.push('/login');
    }
  }

  const changePassword = () => {
    setModalOpen(true);
  }

  return (
    <header className="header-wrap">
      <h1>{title}</h1>
      <div className="user-info">
        <Notification />
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret>
            Admin
            </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={changePassword}>Change Password</DropdownItem>
            <DropdownItem onClick={logout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ModalBox
          isOpen={isModalOpen}
          ModalTitle="Change Password"
          withHeader={true}
          toggle={toggleModal}
          className="modal-right">
          <ChangePassword />
        </ModalBox>
      </div>
    </header>
  )
}
export default (HeaderFile);
