import React from "react";
import { useAudioPlayer, useAudioPosition } from "react-use-audio-player";
import * as Solid from "@heroicons/react/24/solid";
import * as Outline from "@heroicons/react/24/outline";
import { useModal } from "react-modal-hook";
import PreloadImage from "react-preload-image";
import { saveAs } from "file-saver";
import { ModalVotes } from "../modal-votes";
import { ModalPartners } from "../modal-partners";
import "./style.scss";

export const PlayBackMusic = ({ songs, current, setCurrent }) => {
  const playback = songs[current];
  const [showVotesModal, hideVotesModal] = useModal(
    () => <ModalVotes playback={playback} hideModal={hideVotesModal} />,
    [current]
  );
  const [showPartnersModal, hidePartnersModal] = useModal(
    () => <ModalPartners playback={playback} hideModal={hidePartnersModal} />,
    [current]
  );
  const { togglePlayPause, ready, loading, playing, mute, volume } =
    useAudioPlayer({
      src: playback.media.url_complete,
      format: "mp3",
      autoplay: true,
      onend: () => {
        nextTrack();
      },
    });
  const { percentComplete, duration, seek, position } = useAudioPosition({
    highRefreshRate: true,
  });

  const reset = () => {
    seek(0);
  };

  const handleSeek = (e) => {
    const value = Number(e.target.value);
    seek((duration * value) / 100);
  };

  const toggleMute = () => {
    if (!mute()) {
      mute(true);
      volume(0);
    } else {
      mute(false);
      volume(1);
    }
  };

  const handleVolume = (e) => {
    const value = Number(e.target.value);
    volume((value * 1.0) / 100);
  };

  const prevTrack = () => {
    const prev = current - 1;
    if (prev > 0) {
      setCurrent(prev);
    } else {
      setCurrent(songs.length - 1);
    }
  };

  const nextTrack = () => {
    const next = current + 1;
    if (next <= songs.length - 1) {
      setCurrent(next);
    } else {
      setCurrent(0);
    }
  };

  const formatTime = (seconds) => {
    let minute = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    let second = Math.round(seconds % 60);
    second = second < 10 ? "0" + second : second;
    return `${minute}:${second}`;
  };

  if (!ready && !loading) {
    return (
      <div className="playback loading">
        <h3 className="text-not-music">No music to play</h3>
      </div>
    );
  }
  if (loading) {
    return (
      <div className="playback loading">
        <h3 className="text-loading-music">Loading music...</h3>
      </div>
    );
  }

  return (
    <div className="playback">
      <div className="playlist">
        <PreloadImage
          className="image"
          src={playback.media.thumbnail_url}
          alt={playback.media.name}
          lazy
        />
        <div className="info">
          <h3 className="title">{playback.media.title}</h3>
          <p className="subtitle" onClick={showPartnersModal}>
            {playback.users.length}{" "}
            {playback.users.length > 1 || playback.users.length === 0 ? (
              <>Partners</>
            ) : (
              <>Partner</>
            )}
          </p>
          <button
            className="download"
            onClick={() =>
              saveAs(playback.media.url_complete, `${playback.media.title}.mp3`)
            }
          >
            <Solid.ArrowDownTrayIcon className="download-file" />
            Download Music
          </button>
          <button
            className="download photo"
            onClick={() =>
              saveAs(playback.media.thumbnail_url, `${playback.media.title}.jpeg`)
            }
          >
            <Solid.ArrowDownTrayIcon className="download-file" />
            Download Thumbnail
          </button>
        </div>
        <button className="featured" onClick={showVotesModal}>
          {playback.votes > 0 ? (
            <Solid.HeartIcon className="heart-icon active" />
          ) : (
            <Outline.HeartIcon className="heart-icon" />
          )}
          <span className="votes">
            {playback.votes}{" "}
            {playback.votes > 1 || playback.votes === 0 ? (
              <>votes</>
            ) : (
              <>vote</>
            )}
          </span>
        </button>
      </div>
      <div className="playsong">
        <div className="controls">
          <button
            disabled={songs.length === 1}
            className="step"
            onClick={prevTrack}
          >
            <Solid.BackwardIcon className="step-icon" />
          </button>
          <button className="player" onClick={togglePlayPause}>
            {playing ? (
              <Solid.PauseIcon className="player-icon" />
            ) : (
              <Solid.PlayIcon className="player-icon" />
            )}
          </button>
          <button
            disabled={songs.length === 1}
            className="step"
            onClick={nextTrack}
          >
            <Solid.ForwardIcon className="step-icon" />
          </button>
          <button className="step" onClick={reset}>
            <Solid.ArrowPathIcon className="step-icon reset" />
          </button>
        </div>
        <div className="control-duration">
          <span className="position">{formatTime(position)}</span>
          <div className="timeline">
            <input
              className="timeline-range"
              type="range"
              min={0}
              max={100}
              value={percentComplete}
              onChange={handleSeek}
            />
            <progress
              className="timeline-bar"
              max={100}
              value={percentComplete}
            />
          </div>
          <span className="duration">{formatTime(duration)}</span>
        </div>
      </div>
      <div className="control-volume">
        <span className="mute" onClick={toggleMute}>
          {mute() || volume() === 0 ? (
            <Solid.SpeakerXMarkIcon />
          ) : (
            <Solid.SpeakerWaveIcon />
          )}
        </span>
        <div className="volume">
          <input
            className="volume-range"
            disabled={!playing}
            type="range"
            min={0}
            max={100}
            value={volume() * 100}
            onChange={handleVolume}
          />
          <progress className="volume-bar" max={100} value={volume() * 100} />
        </div>
      </div>
    </div>
  );
};
