import React from 'react';
import DataTable from 'react-data-table-component';
import DummyIcon from '../../assets/images/user.jpg';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { supportList, removeSupportMessages } from '../../api/admin';
import { supportListData } from './supportAction';
import Pagination from "react-js-pagination";
import { dateFormat, timeFormat } from '../../utils/method';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createMarkup(html) {
  return { __html: html };
}

class SupportListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      activePage: 1,
      limit: 10,
      supportLists: {},
      sort: false,
      sortBy: null
    };
  }

  getSupportList = async (sortDir = this.state.sort, sortBy = this.state.sortBy) => {
    const { activePage, limit } = this.state;
    const support = await this.props.supportList(activePage, limit, sortBy, sortDir);
    if (support && support.data && support.data.list.length) {
      this.setState({ supportLists: support.data, totalCounts: support.data.totalCounts });
    }
    this.props.setLoading(false);
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.props.setTitlePage('Support Management');
    this.getSupportList();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getSupportList();
    });
    this.props.setLoading(true);
  }

  onSort = (e) => {
    this.setState({
      sort: !this.state.sort,
      sortBy: e.selector
    }, () => {
      this.getSupportList(this.state.sort, e.selector === 'name' ? e.selector : null);
    });
    this.props.setLoading(true);
  }

  onDetailsPage = (rowData) => {
    this.props.supportListData(rowData);
    this.props.history.push('/subscription-plan');
  }

  removeMessages = async (message) => {
    this.props.setLoading(true);
    const removeMsg = await this.props.removeSupportMessages(message.id);
    if (removeMsg && removeMsg.data) {
      toast(removeMsg.data);
    }
    this.getSupportList();
    this.props.setLoading(false);
  }

  columns = [
    {
      name: 'Name & Email',
      selector: 'name',
      sortable: true,
      cell: row => <div className="user-wrapper" onClick={() => this.onDetailsPage(row)}>
        <NavLink to="#" className="link-wrap">
          <img height="33" width="33" alt={row.user.name} src={row.user.profile_image ? row.user.profile_image : DummyIcon} />
          <span className="user-detail">
            <span className="name">{row.user.name}</span>
            <span className="email-wrap">{row.user.email}</span>
          </span>
        </NavLink>
      </div>
    },
    {
      name: 'Date & Time',
      selector: 'Datetime',
      sortable: true,
      cell: row => <span className="user-detail">
        <span className="name">{dateFormat(row.createdAt)}  |  {timeFormat(row.createdAt)}</span>
      </span>
    },
    {
      name: 'Subject',
      selector: 'subject'
    },
    {
      name: 'Message',
      selector: 'message',
      cell: (row) => <span dangerouslySetInnerHTML={createMarkup(row.message)}></span>
    },
    {
      name: 'Action',
      selector: 'action',
      center: true,
      cell: (row) => <span className="remove" onClick={() => this.removeMessages(row)}>Remove</span>
    }
  ];

  render() {
    const { totalCounts, activePage, limit, supportLists } = this.state;
    return (
      <>
        <DataTable
          title="Support"
          columns={this.columns}
          className="custom-table with-5-col"
          data={supportLists && supportLists.list}
          onSort={this.onSort}
        />
        {totalCounts > this.state.limit && <Pagination
          activePage={activePage}
          itemsCountPerPage={limit}
          totalItemsCount={totalCounts}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.user,
    userSupportData: state.supports
  }
}

export default connect(mapStateToProps, {
  supportList,
  supportListData,
  removeSupportMessages
})(withRouter(SupportListing));

