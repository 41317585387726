import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import UserSelectComponent from '../../components/shared/GlobalComponent/UserSelectComponent';
import { createSchedulerParlour } from '../../api/scheduler-parlour';

function FormParlour(props) {
  const getDateNow = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const [form, setForm] = React.useState({
    username: '',
    suite_title: '',
    description: '',
    start_date: getDateNow()
  });
  const handleChange = (e, name) => {
    setForm({
      ...form,
      [name ?? e.target.name]: e.target.type === "file" ? (e.target.files.length ? e.target.files[0] : null)  : e.target.value
    });
  };
  const handleSelectUser = (e, item) => {
    setForm({ ...form, user_id: item.id, username: item.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { username, ...payload } = form;
      props.setLoading(true)
      const { data } = await props.createSchedulerParlour(payload);
      props.addParlour(data);
      props.toggleModalForm();
    } catch (error) { }
    finally {
      props.setLoading(false);
    }
  }
  return (
    <Form className="create-parlour" >
      <FormGroup className="user-search-control">
        <Label for="username">Select User</Label>
        <UserSelectComponent 
          id="username"
          name="username"
          value={form.username}
          onChange={(e) => handleChange(e, 'username')}
          onSelect={handleSelectUser}
        />
      </FormGroup>
      <FormGroup>
        <Label for="suite_title">Title</Label>
        <Input type="text" autoFocus name="suite_title" value={form.suite_title} id="suite_title" placeholder="Enter title" onChange={handleChange} required />
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <Input type="textarea" name="description" value={form.description} id="description" placeholder="Enter description" onChange={handleChange} required />
      </FormGroup>
      <FormGroup>
        <Label for="start_date">Start Date</Label>
        <Input type="datetime-local" name="start_date" value={form.start_date} id="start_date" placeholder="Enter start date" onChange={handleChange} required />
      </FormGroup>
      <FormGroup className="file-field">
        <Label for="poster">Poster</Label>
        <div className="add-file-btn">
          <Input 
            type="file"
            id="poster"
            name="poster"
            onChange={handleChange}
            accept="image/*"
            required
          />
        </div>
        <div className='image-preview'>
          {form.poster && (
            <img src={URL.createObjectURL(form.poster)} alt="poster" className='img-thumbnail' />
          )}
        </div>
      </FormGroup>
      <FormGroup className="btn-wrap">
        <Button color="primary" onClick={handleSubmit} disabled={props.loading} >
          {props.loading ? 'Loading...' : 'Create'}
        </Button>
      </FormGroup>
    </Form>
  )
}

export default connect(null, {
  createSchedulerParlour,
})(withRouter(FormParlour));