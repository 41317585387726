import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import DummyIcon from '../../assets/images/user.jpg'
import { NavLink } from 'react-router-dom'
import ModalBox from '../../components/shared/pageComponent/ModalBox'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Pagination from 'react-js-pagination'
import Axios from 'axios'
import './postDetail.css'

const Bulletins = ({ history, ...props }) => {
  const [bulletinList, setBulletinList] = useState([])
  const [totalCounts, setTotalCounts] = useState(1)
  const [activePage, setActivePage] = useState(1)
  const [singleUser, setSingleUser] = useState()
  const [postToggle, setPostToggle] = useState(false)

  useEffect(() => {
    const raw = async () => {
      props.setLoading(() => true)
      const bulletinss = await Axios.get(`admin/bulletins/?page=${activePage}`)
      setBulletinList(() => bulletinss.data)
      setTotalCounts(() => bulletinss.data.totalCounts)
      props.setLoading(() => false)
    }
    raw()
  }, [activePage])
  
  useEffect(() => {
    props.setTitlePage('Post Management');
  }, []);

  const handlePageChange = async (n) => {
    try{
    props.setLoading(() => true)
    const bulletinss = await Axios.get(`admin/bulletins/?page=${n}`)

    setBulletinList(() => bulletinss.data)
    setActivePage(() => n)
    props.setLoading(() => false)
    } catch(error) {
    } 
  }

  const changeStatus = async (id) => {
    try {
      props.setLoading(() => true)
      const update = await Axios.put(`admin/bulletins/${id}`)
      const bulletinss = await Axios.get(`admin/bulletins/?page=${activePage}`)
      setBulletinList(() => bulletinss.data)
      props.setLoading(() => false)
      toast(update.data.msg)
    } catch (error) {
      const bulletinss = await Axios.get(`admin/bulletins/?page=${activePage}`)
      setBulletinList(() => bulletinss.data)
      props.setLoading(() => false)
    }
  }

  const showDetails = async (id) => {
    try {
      props.setLoading(() => true)
      const bulletinsss = await Axios.get(`admin/bulletins/${id}`)
      setPostToggle((p) => !p)
      setSingleUser(bulletinsss.data.data)
      props.setLoading(() => false)
    } catch (error) {
      toast(error.msg)
    }
  }

  const hideDetails = () => {
    setPostToggle(() => false)
  }

  const columns = [
    {
      name: 'Name & Email',
      selector: 'user.name',
      cell: (row) => (
        <div className="user-wrapper">
          <NavLink className="link-wrap" to={`/user-detail/${row.user.id}`}>
            <img height="33" width="33" alt={row.user.name} src={row.user.profile_image_url ? row.user.profile_image_url : DummyIcon} />
            <span className="user-detail">
              <span className="name">{row.user.name}</span>
              <span className="email-wrap">{row.user.email}</span>
            </span>
          </NavLink>
        </div>
      )
    },
    { name: 'Bulletin Title', selector: 'title' },
    { name: 'Description', selector: 'description' },
    {
      name: 'Talent Type',
      selector: '',
      cell: (row) => (
        <div className="user-wrapper last-item">
          {row.roles.map((d, index, arr) => (
            <>{index === arr.length - 1 ? <span key={index}>{d.title}</span> : <span key={d.id}>{d.title}, </span>}</>
          ))}
        </div>
      )
    },
    {
      name: 'No. of Response',
      selector: '',
      center: true,
      cell: (row) => <div className="user-wrapper">{row.responses && row.responses.length ? row.responses.length : 'N/A'}</div>
    },
    {
      name: 'Status',
      selector: '',
      center: true,
      cell: (row) =>
        row.status === true ? (
          <div>
            <span className="active-label">Active</span>
          </div>
        ) : (
          <div>
            <span className="inactive">Inactive</span>
          </div>
        )
    },
    {
      selector: 'action',
      cell: (row) => (
        <div>
          <div className="action-wrap">
            <div id={row.id} className="child-wrap">
              {row.status === true ? (
                <div className="activeInactive">
                  <span className="item padding" onClick={() => showDetails(row.id)}>
                    View Details
                  </span>
                  <span className="item inactive padd" onClick={() => changeStatus(row.id)}>
                    Inactive
                  </span>
                </div>
              ) : (
                <div className="activeInactive">
                  <span className="item padding" onClick={() => showDetails(row.id)}>
                    View Details
                  </span>
                  <span className="item active-label padd" onClick={() => changeStatus(row.id)}>
                    Active
                  </span>
                </div>
              )}
            </div>
            <span className="dots-btn">dots</span>
          </div>
        </div>
      )
    }
  ]

  return (
    <>
      <div className="data-search-bar">
        <h3>Bulletin Board</h3>
        <ModalBox className="modal-right" isOpen={postToggle} withHeader={true} ModalTitle="Post Details" toggle={hideDetails}>
          {singleUser && (
            <div>
              <span className="post-posted-by">Posted By</span>
              <div className="post-wrapper">
                <div className="post-user-wrapper">
                  <NavLink className="post-link-wrap" to={`/user-detail/${singleUser.user.id}`}>
                    <img
                      height="33"
                      width="33"
                      alt={singleUser.user.name}
                      src={singleUser.user.profile_image_url ? singleUser.user.profile_image_url : DummyIcon}
                    />
                    <span className="post-user-detail">
                      <span className="post-name">{singleUser.user.name}</span>
                      <span className="post-email-wrap">{singleUser.user.email}</span>
                    </span>
                  </NavLink>
                  {singleUser.status === true ? <span className="item active-label">Active</span> : <span className="item inactive">Inactive</span>}
                </div>
              </div>
              <p className="post-title">{singleUser.title}</p>
              {singleUser.roles.map((d) => (
                <span key={d.id} className="post-talent-type">
                  {d.title}
                </span>
              ))}
              <p className="post-description">{singleUser.description}</p>
              <p className="post-response">No of Response</p>
              {singleUser.responses.length}
            </div>
          )}
        </ModalBox>
      </div>
      <DataTable columns={columns} className="custom-table user-table" data={bulletinList && bulletinList.data} />
      {totalCounts > 10 && (
        <Pagination
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={totalCounts}
          pageRangeDisplayed={5}
          onChange={(num) => handlePageChange(num)}
        />
      )}
    </>
  )
}

export default Bulletins
