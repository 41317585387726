import React from 'react'
import Autocomplete from 'react-autocomplete'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchUserByName } from '../../../api/admin';
import DummyIcon from '../../../assets/images/user.jpg';

function UserSelectComponent(props) {
  const [items, setItems] = React.useState([]);

  const searchInputChange = async (e) => {
    props.onChange(e);
    const inputValue = e.target.value;
    if (inputValue.length < 2) {
      return;
    }
    const { data } = await props.searchUserByName(inputValue.trim());
    const userslist = data.map((user, key) => {
      return {
        value: user.name.trim(),
        id: user.id,
        label: <div className="list-with-icon" key={key}>
          <span className="img-wrap">
            <img className="small-icon" src={user.profile_image ? user.profile_image : DummyIcon} alt="red" height="30px" width="30px" />
          </span>
          <div className="user-info">
            <strong className="user-name">{user.name.trim()}</strong>
            <small className="email">{user.email}</small>
          </div>
        </div>
      };
    });
    setItems(userslist);
  }
  return (
    <Autocomplete
      id={props.id}
      name={props.name}
      getItemValue={(item) => item.value}
      items={items}
      renderItem={(item) => item.label}
      onChange={searchInputChange}
      onSelect={props.onSelect}
      value={props.value}
    />
  )
}

export default connect(null, {
  searchUserByName,
})(withRouter(UserSelectComponent));
