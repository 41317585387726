import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ModalBox from "../../components/shared/pageComponent/ModalBox";
import DataTable from "react-data-table-component";
import DummyIcon from "../../assets/images/user.jpg";
import { NavLink, withRouter } from "react-router-dom";
import HeadlineForm from "./HeadlineForm";
import {
  getAwsCredentials,
  createHeadline,
  getNewsList,
  getIdUser,
  deleteNews,
} from "../../api/admin";
import { connect } from "react-redux";
import S3FileUpload from "react-s3";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "react-js-pagination";

class HeadlineListing extends React.Component {
  constructor(props) {
    super(props);
    this.InputSwitch = React.createRef();
    this.state = {
      user_id: null,
      newsId: null,
      modal: false,
      selected: 1,
      isModalOpen: false,
      title: "",
      type: "text",
      video_url: null,
      description: "",
      website_link: "",
      news_photo: [],
      awsCredentials: null,
      newsList: [],
      totalCounts: 0,
      limit: 10,
      activePage: 1,
      sort: false,
      sortBy: null,
    };
  }

  getNewsList = async (
    pageNumber,
    sortBy = this.state.sortBy,
    sortDir = this.state.sort
  ) => {
    const newsList = await this.props.getNewsList(
      pageNumber ? pageNumber : 1,
      sortBy,
      sortDir
    );
    this.setState({
      newsList: newsList && newsList.news,
      totalCounts: newsList.totalCounts,
    });
    this.props.setLoading(false);
  };

  async componentDidMount() {
    this.props.setLoading(true);
    this.props.setTitlePage("Headline Management");
    await this.getNewsList();
    const awsCredentials = await this.props.getAwsCredentials();
    const userResponse = await this.props.getIdUser();
    const user_id = userResponse.data.user_id;
    this.setState({ awsCredentials, user_id });
    this.props.setLoading(false);
  }

  activeType = (selected, type) =>
    this.setState({
      selected,
      type,
    });

  toggle = () =>
    this.setState({
      modal: !this.state.modal,
    });

  toggleModal = () =>
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });

  columns = [
    {
      name: "Owner",
      selector: "Owner",
      sortable: true,
      cell: (row) => (
        <div className="user-wrapper">
          <NavLink className="link-wrap" to={`/podcast-detail/${row.id}`}>
            <img
              height="33"
              width="33"
              alt={row.user.name}
              src={row.user.profile_image ? row.user.profile_image : DummyIcon}
            />
            <span className="user-detail">
              <span className="name">{row.user.name}</span>
              <span className="email-wrap">{row.user.email}</span>
            </span>
          </NavLink>
        </div>
      ),
    },
    {
      name: "Title",
      selector: "title",
      center: true,
      cell: (row) => (
        <div className="podcast-wrapper">
          <span className="name">{row.title}</span>
        </div>
      ),
    },
    {
      name: "Video Url",
      selector: "video_url",
      center: true,
      cell: (row) => (
        <div className="podcast-wrapper">
          <span className="name">{row.video_url || "N/A"}</span>
        </div>
      ),
    },
    {
      name: "Description",
      selector: "description",
      center: true,
      cell: (row) => (
        <div className="podcast-wrapper">
          <span className="name">{row.description || "N/A"}</span>
        </div>
      ),
    },
    {
      name: "Website Link",
      selector: "website_link",
      center: true,
      cell: (row) => (
        <div className="podcast-wrapper">
          <span className="name">{row.website_link || "N/A"}</span>
        </div>
      ),
    },
    {
      name: "News Photo",
      selector: "news_photo",
      center: true,
      cell: (row) => (
        <div className="podcast-wrapper">
          <span className="name">
            {row.news_photo ? row.news_photo.length : "N/A"}
          </span>
        </div>
      ),
    },
    {
      name: "Action",
      selector: "action",
      center: true,
      cell: (row) => (
        <button
          className="RemoveBtn"
          onClick={() => this.selectIdDelete(row.id)}
        >
          Remove
        </button>
      ),
    },
  ];

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
    if (e.target.value === "") {
      e.target.classList.add("error");
    } else {
      e.target.classList.remove("error");
    }
  };

  uploadVideoOns3 = async () => {
    try {
      this.props.setLoading(true);
      const userId = this.state.user_id;
      const folder = `users/${userId}/headlines`;
      const { bucket_name, aws_access_key_id, aws_secret_access_key } =
        this.state.awsCredentials.s3;
      const config = {
        bucketName: bucket_name,
        dirName: folder,
        region: "us-east-1",
        accessKeyId: aws_access_key_id,
        secretAccessKey: aws_secret_access_key,
      };
      const data = await S3FileUpload.uploadFile(this.state.video_url, config);
      return `/${data.key}`;
    } catch (error) {
      return null;
    } finally {
      this.props.setLoading(false);
    }
  };

  uploadPhotosOns3 = async () => {
    try {
      this.props.setLoading(true);
      const userId = this.state.user_id;
      const folder = `users/${userId}/projects`;
      const { bucket_name, aws_access_key_id, aws_secret_access_key } =
        this.state.awsCredentials.s3;
      const config = {
        bucketName: bucket_name,
        dirName: folder,
        region: "us-east-1",
        accessKeyId: aws_access_key_id,
        secretAccessKey: aws_secret_access_key,
      };
      const news_photo = [];
      for await (let file of this.state.news_photo) {
        const data = await S3FileUpload.uploadFile(file, config);
        const photo = `/${data.key}`;
        news_photo.push(photo);
      }
      return news_photo;
    } catch (error) {
      return null;
    } finally {
      this.props.setLoading(false);
    }
  };

  createHeadline = async () => {
    const { selected, title, type, description, website_link } = this.state;
    if (!title && !type && !description && !website_link) {
      return;
    } else if (!title) {
      toast("Please enter news title!", { type: "error" });
      return;
    }
    this.props.setLoading(true);
    const data = {
      title: title,
      type: type,
      website_link: website_link,
    };
    if (selected === 1) {
      data.description = description;
    }
    if (selected === 2) {
      const video_url = await this.uploadVideoOns3();
      if (video_url) {
        data.video_url = video_url;
      } else {
        data.video_url = null;
      }
    }
    if (selected === 3) {
      const news_photo = await this.uploadPhotosOns3();
      if (news_photo) {
        data.news_photo = news_photo;
      } else {
        data.news_photo = null;
      }
    }
    const create = await this.props.createHeadline(data);
    if (create && create.status === 201) {
      toast("News created successfully!");
      this.setState({
        isModalOpen: false,
        title: "",
        type: "text",
        video_url: null,
        description: "",
        website_link: "",
        news_photo: [],
      });
      this.props.setLoading(true);
      this.getNewsList();
    } else if (create && create.data) {
      toast(create.data, { type: "error" });
    }
    this.props.setLoading(false);
  };

  deleteHeadline = async () => {
    this.toggle();
    this.props.setLoading(true);
    const remove = await this.props.deleteNews(this.state.newsId);
    if (remove && remove.status === 200) {
      this.props.setLoading(true);
      this.getNewsList();
    } else if (remove && remove.data) {
      toast(remove.data, { type: "error" });
    }
    this.ptops.setLoading(false);
  };

  selectIdDelete = (newsId) => {
    this.toggle();
    this.setState({ newsId });
  };

  validateDurationVideo(url, callback) {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(video.src);
      if (video.duration > 60) {
        callback(false);
      } else {
        callback(true);
      }
    };
    video.src = URL.createObjectURL(url);
  }
  onVideoChange = (file) => {
    const videoFile = file.target.files[0];
    const formData = new FormData();
    formData.append(
      "video",
      videoFile,
      `video-${this.state.user_id}-${Date.now()}.mp4`
    );
    this.validateDurationVideo(formData.get("video"), (status) => {
      if (!status) {
        return toast("You cannot upload a video longer than 60 seconds", {
          type: "warning",
        });
      }
      this.setState({ video_url: formData.get("video") });
    });
  };
  onPhotosChange = (file) => {
    const photoFile = file.target.files[0];
    if (this.state.news_photo.length === 5) {
      return toast("News cannot contain more that 5 images", { type: "info" });
    }
    this.setState({ news_photo: [...this.state.news_photo, photoFile] });
  };

  removePhoto = (index) => {
    this.state.news_photo.splice(index, 1);
    this.setState({ news_photo: this.state.news_photo });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getNewsList(pageNumber);
    });
    this.props.setLoading(true);
  }

  onSort = (e) => {
    this.setState(
      {
        sort: !this.state.sort,
        sortBy: e.selector,
      },
      () => {
        this.getNewsList(this.state.activePage, e.selector, this.state.sort);
      }
    );
    this.props.setLoading(true);
  };

  render() {
    const {
      selected,
      title,
      video_url,
      description,
      website_link,
      news_photo,
      newsList,
      limit,
      totalCounts,
      activePage,
    } = this.state;
    return (
      <>
        <div className="header-section minus-space">
          <div className="create-podcast">
            <Button color="primary" onClick={this.toggleModal}>
              Create Headline
            </Button>
            <ModalBox
              isOpen={this.state.isModalOpen}
              ModalTitle="Add Headlines"
              withHeader={true}
              toggle={this.toggleModal}
              className="modal-right"
            >
              <HeadlineForm
                loaded={this.props.loading}
                selected={selected}
                title={title}
                description={description}
                website_link={website_link}
                video_url={video_url}
                news_photo={news_photo}
                activeType={this.activeType}
                handleChange={this.handleChange}
                createHeadline={this.createHeadline}
                onVideoChange={this.onVideoChange}
                onPhotosChange={this.onPhotosChange}
                removePhoto={this.removePhoto}
              />
            </ModalBox>
          </div>
        </div>
        <DataTable
          title="Manage Headline"
          columns={this.columns}
          className="custom-table with-5-col"
          data={newsList}
          onSort={this.onSort}
        />
        {totalCounts > 0 && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={limit}
            totalItemsCount={totalCounts}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
          />
        )}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Delete News</ModalHeader>
          <ModalBody>Are you sure to delete the news</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteHeadline}>
              Yes, delete
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    admin: store.user,
  };
};

export default connect(mapStateToProps, {
  getAwsCredentials,
  createHeadline,
  getNewsList,
  getIdUser,
  deleteNews,
})(withRouter(HeadlineListing));
