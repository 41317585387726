import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableSellParlours from './TableSellParlours';

function FinishedParlours(props) {
  React.useEffect(() => {
    props.setTitlePage('Finished Parlours');
  }, []);
  
  return (
    <TableSellParlours
      setLoading={props.setLoading}
    />
  )
}

export default connect(null, null)(withRouter(FinishedParlours));