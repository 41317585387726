import axios from "axios";
import "./interceptors";
import { Numbers } from "../constants/constant";

export function userLogin(userDetails) {
  return (dispatch) => {
    let data = { email: userDetails.email, password: userDetails.password };
    return axios
      .post(`admin/users/login`, data)
      .then((res) => {
        dispatch({
          type: "SET_AUTH",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function userPasswordForgot(userEmail) {
  return (dispatch) => {
    return axios
      .post(`admin/users/send-password-link`, userEmail)
      .then((res) => {
        dispatch({
          type: "USER_FORGOT_PASSWORD",
          data: res,
        });
        return res;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function userList(page, limit, sortBy, sortDir) {
  return (dispatch) => {
    let url = `admin/users?page=${page ? page : Numbers.one}&limit=${
      limit ? limit : Numbers.ten
    }`;
    if (sortBy) {
      url = `${url}&sort_by=${sortBy}&sort_dir=${sortDir ? "asc" : "desc"}`;
    }
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "SET_AUTH",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function ChangeUserStatus(user, status) {
  const data = { active: status === "active" ? true : false };
  return (dispatch) => {
    return axios
      .patch(`admin/users/${user}`, data)
      .then((res) => {
        dispatch({
          type: "USER_STATUS",
          data: res,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function userResetPassword(userPassword, isAdmin) {
  const data = { password: userPassword };
  return (dispatch) => {
    const url =
      isAdmin === "true"
        ? "admin/users/reset-password"
        : "api/v1/users/reset-password";
    return axios
      .post(url, data)
      .then((res) => {
        dispatch({
          type: "USER_RESET_PASSWORD",
          data: res,
        });
        return res;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function emailVerification() {
  return (dispatch) => {
    return axios
      .get(`api/v1/users/email-verify`)
      .then((res) => {
        dispatch({
          type: "EMAIL_VERIFY",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function userDetail(id) {
  return (dispatch) => {
    return axios
      .get(`admin/users/${id}`)
      .then((res) => {
        dispatch({
          type: "GET_USER_DETAILS",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function getAwsCredentials(id) {
  return (dispatch) => {
    return axios
      .get(`api/v1/configurations`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function projectDetail(id) {
  return (dispatch) => {
    return axios
      .get(`admin/projects/${id}`)
      .then((res) => {
        dispatch({
          type: "GET_PROJECTS_DETAILS",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function userUpdate(userId, data) {
  return (dispatch) => {
    return axios
      .post(`admin/users/${userId}`, data)
      .then((res) => {
        dispatch({
          type: "USER_UPDATE",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function searchUser(searchData, page, limit, sortBy, sortDir) {
  let url = `admin/users?page=${page ? page : Numbers.one}&limit=${
    limit ? limit : Numbers.ten
  }&search=${searchData}`;
  if (sortBy) {
    url = `${url}&sort_by=${sortBy}&sort_dir=${sortDir ? "asc" : "desc"}`;
  }
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "GET_SEARCH_USER",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function filterUser(args) {
  let {
    city,
    state,
    zipcode,
    roleIds,
    profile_type,
    start_date,
    end_date,
    page,
    limit,
    sortBy,
    sortDir,
  } = args;
  let url = `admin/users?page=${page ? page : Numbers.one}&limit=${
    limit ? limit : Numbers.ten
  }`;
  if (city) url = `${url}&city=${city}`;
  if (state) url = `${url}&state=${state}`;
  if (zipcode) url = `${url}&zipcode=${zipcode}`;
  if (start_date && end_date) url = `${url}&start_date=${start_date}&end_date=${end_date}`;
  if (roleIds.length) url = `${url}&role_ids=${JSON.stringify(roleIds)}`;
  if (profile_type)
    url = `${url}&profile_type=${
      profile_type === "Featured" ? "true" : "false"
    }`;
  if (sortBy) {
    url = `${url}&sort_by=${sortBy}&sort_dir=${sortDir ? "asc" : "desc"}`;
  }
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "FILTER_USER",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function searchUserByName(searchData) {
  let url = `admin/users/search-by-name?name=${searchData}`;
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function deleteAudioTrack(audioId) {
  return (dispatch) => {
    return axios
      .delete(`admin/media/${audioId}`)
      .then((res) => {
        dispatch({
          type: "DELETE_USER_MEDIA",
          data: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function deleteProjects(projectId) {
  return (dispatch) => {
    return axios
      .delete(`admin/projects/${projectId}`)
      .then((res) => {
        dispatch({
          type: "DELETE_USER_PROJECT",
          data: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function createPodcast(data) {
  return (dispatch) => {
    return axios
      .post(`admin/podcasts`, data)
      .then((res) => {
        dispatch({
          type: "USER_CREATE_PODCAST",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function createHeadline(data) {
  return (dispatch) => {
    return axios
      .post(`admin/news`, data)
      .then((res) => {
        dispatch({
          type: "USER_CREATE_HEADLINE",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function sortUserList(page) {
  return (dispatch) => {
    return axios
      .get(`admin/users?sort_by=${page ? "asc" : "desc"}`)
      .then((res) => {
        dispatch({
          type: "SORT_USER_LIST",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function getIdUser() {
  return (dispatch) => {
    return axios
      .get(`admin/users/user/id`)
      .then((res) => {
        dispatch({
          type: "USER_ID",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function dashboardStatics() {
  return (dispatch) => {
    return axios
      .get(`admin/dashboard`)
      .then((res) => {
        dispatch({
          type: "DASHBOARD_STATICS",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function getPodcastList(page, sortBy, sortDir = false, limit = 10) {
  let url = `admin/podcasts?page=${page}&limit=${limit}`;
  if (sortBy) {
    url = `${url}&sort_by=${sortBy}&sort_dir=${sortDir ? "asc" : "desc"}`;
  }
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "GET_PODCAST_LIST",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function getNewsList(page, sortBy, sortDir = false, limit = 10) {
  let url = `admin/news?page=${page}&limit=${limit}`;
  if (sortBy) {
    url = `${url}&sort_by=${sortBy}&sort_dir=${sortDir ? "asc" : "desc"}`;
  }
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "GET_NEWS_LIST",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function getPodcastDetails(Id) {
  return (dispatch) => {
    return axios
      .get(`admin/podcasts/${Id}`)
      .then((res) => {
        dispatch({
          type: "GET_PODCAST_DETAILS",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function updatePodcast(Id, data) {
  return (dispatch) => {
    return axios
      .post(`admin/podcasts/${Id}`, data)
      .then((res) => {
        dispatch({
          type: "USER_UPDATE_PODCAST",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function deleteMediaViaUrl(Id) {
  let url =
    typeof Id === "number"
      ? `admin/media/${Id}`
      : `admin/media/s3?url_key=${Id}`;
  return (dispatch) => {
    return axios
      .delete(url)
      .then((res) => {
        dispatch({
          type: "USER_DELETE_MEDIA",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function deletePodcast(Id) {
  return (dispatch) => {
    return axios
      .delete(`admin/podcasts/${Id}`)
      .then((res) => {
        dispatch({
          type: "USER_DELETE_PODCAST",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function deleteNews(Id) {
  return (dispatch) => {
    return axios
      .delete(`admin/news/${Id}`)
      .then((res) => {
        dispatch({
          type: "USER_DELETE_NEWS",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function supportList(page, limit, sortBy, sortDir = false) {
  return (dispatch) => {
    let url = `admin/supports?page=${page ? page : Numbers.one}&limit=${
      limit ? limit : Numbers.ten
    }`;
    url = `${url}&sort_dir=${sortDir ? "asc" : "desc"}`;
    if (sortBy === "name") {
      url = `${url}&sort_by=${sortBy}`;
    }
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "SUPPORT_LIST",
          data: res,
        });
        return res;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function userEmailReply(data) {
  return (dispatch) => {
    return axios
      .post(`admin/supports`, data)
      .then((res) => {
        dispatch({
          type: "USER_EMAIL_REPLY",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function removeSupportMessages(Id) {
  return (dispatch) => {
    return axios
      .delete(`admin/supports/${Id}`)
      .then((res) => {
        dispatch({
          type: "ADMIN_DELETE_SUPPORT_MESSAGES",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function changeAdminPasswordApi(data) {
  return (dispatch) => {
    return axios
      .post(`admin/users/change-password`, data)
      .then((res) => {
        dispatch({
          type: "ADMIN_PASSWORD_CHANGE",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export function getNotificationList(page, limit, read) {
  const url = `admin/notifications?page=${page}&limit=${limit}${
    read ? "&read=" + read : ""
  }`;
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "NOTIFICATION_LIST_ACTION",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function dashboardGraphData(data) {
  return (dispatch) => {
    return axios
      .post(`admin/dashboard/get-graph-data`, data)
      .then((res) => {
        dispatch({
          type: "DASHBOARD_GRAPH_DATA",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };
}

export function deleteUser(id) {
  return (dispatch) => {
    return axios
      .delete(`admin/users/${id}`)
      .then((res) => {
        dispatch({
          type: "ADMIN_DELETE_USER",
          data: res,
        });
        return res;
      })
      .catch((error) => {
        return error && error.response;
      });
  };
}

export default {
  userLogin,
};
