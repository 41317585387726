import Login from "../containers/users/login/Login";
import Signup from "../containers/users/signup/Signup";
import ForgotPassword from "../containers/users/forgotpassword/ForgotPassword";
import Dashboard from "../containers/dashboard/Dashboard";
import UsersListing from "../containers/users/mangement/Listing";
import UserlistingDetail from "../containers/users/mangement/ListingDetail";
import EditProfile from "../containers/users/mangement/EditProfile";
import PodcastListing from "../containers/posdcasts/Listing";
import PodcastDetail from "../containers/posdcasts/PodcastDetail";
import SupportListing from "../containers/support/SupportListing";
import SubscriptionPlan from "../containers/support/SubscriptionPlan";
import ErrorPage from "../containers/error/ErrorPage";
import ResetPassword from "../containers/users/forgotpassword/ResetPassword";
import ShowMessageContainer from "../containers/users/forgotpassword/ShowMessageContainer";
import ResetPasswordMessage from "../containers/users/forgotpassword/resetPasswordMessage";
import ProjectDetails from "../containers/users/mangement/projectDetails";
import VideoFileOpen from "../containers/users/mangement/video";
import TermsOfUse from "../containers/content/TermsOfUse";
import Privacy from "../containers/content/Privacy";
import BulletinListing from "../containers/post/Listing";
import HeadlineListing from "../containers/headline/Listing";
import SongContest from "../containers/song-contest/SongContest";
import SchedulerParlour from "../containers/scheduler-parlour/SchedulerParlour";
import FinishedParlours from "../containers/scheduler-parlour/FinishedParlours";
import DetailParlour from "../containers/scheduler-parlour/DetailParlour";

const routes = [
  {
    path: "/",
    exact: true,
    component: Login,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/register",
    exact: true,
    component: Signup,
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/email-verification",
    exact: true,
    component: ShowMessageContainer,
  },
  {
    path: "/video/:userId",
    exact: true,
    component: VideoFileOpen,
  },
  {
    path: "/reset-password-confirmation",
    exact: true,
    component: ResetPasswordMessage,
  },
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard,
    authentication: true,
  },
  {
    path: "/user-list",
    exact: true,
    component: UsersListing,
    authentication: true,
  },
  {
    path: "/user-detail",
    exact: true,
    component: UserlistingDetail,
    authentication: true,
  },
  {
    path: "/user-detail/:userId",
    exact: true,
    component: UserlistingDetail,
    authentication: true,
  },
  {
    path: "/project-detail/:projectId",
    exact: true,
    component: ProjectDetails,
    authentication: true,
  },
  {
    path: "/edit-profile/:userId",
    exact: true,
    component: EditProfile,
    authentication: true,
  },
  {
    path: "/podcast-management",
    exact: true,
    component: PodcastListing,
    authentication: true,
  },
  {
    path: "/headline-management",
    exact: true,
    component: HeadlineListing,
    authentication: true,
  },
  {
    path: "/song-contest",
    exact: true,
    component: SongContest,
    authentication: true,
  },
  {
    path: "/scheduler-parlour",
    exact: true,
    component: SchedulerParlour,
    authentication: true,
  },
  {
    path: "/sell-parlour",
    exact: true,
    component: FinishedParlours,
    authentication: true,
  },
  {
    path: "/sell-parlour/detail/:id",
    exact: true,
    component: DetailParlour,
    authentication: true,
  },
  {
    path: "/bulletin-board",
    exact: true,
    component: BulletinListing,
    authentication: true,
  },
  {
    path: "/podcast-detail/:id",
    exact: true,
    component: PodcastDetail,
    authentication: true,
  },
  {
    path: "/support-management",
    exact: true,
    component: SupportListing,
    authentication: true,
  },
  {
    path: "/subscription-plan",
    exact: true,
    component: SubscriptionPlan,
    authentication: true,
  },
  {
    path: "/terms-of-use",
    exact: true,
    component: TermsOfUse,
  },
  {
    path: "/privacy",
    exact: true,
    component: Privacy,
  },
  {
    path: "*",
    exact: true,
    component: ErrorPage,
  },
];

export default routes;
