import React from 'react'
import {connect} from 'react-redux';
import moment from 'moment'
import iconVideo from "../../assets/images/selected-scheduler-parlour.svg";
import { FormGroup, CustomInput } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { updateSchedulerParlour } from '../../api/scheduler-parlour';

function CardParlour({ parlour, loading, updateParlour, getParlourParticipants, handleDeleteParlour, ...props }) {
  const handlerUpdateParlour = async (key) => {
    try {
      props.setLoading(true);
      const { data } = await props.updateSchedulerParlour(
        parlour.id,
        {
          [key]: !parlour[key],
        }
      );
      updateParlour(data);
    } catch (error) {
      console.log(error);
    } finally {
      props.setLoading(false);
    }
  }
  return (
    <div className="card-parlour">
      <div className="card-poster">
        {parlour.live && (
          <div className="card-live">
            <div className="card-live-circle" />
            <span className="card-live-text">Live</span>
          </div>
        )}
        <img
          className="card-img"
          src={parlour.poster_url}
          alt={parlour.suite_title}
          loading="lazy"
        />
        <div className="bg-black" />
        <div className="card-info">
          <h3 className="card-title">{parlour.suite_title}</h3>
          <h1 className="card-description">
            '{parlour.description}'
          </h1>
          <span className="card-date">
            {moment
              .utc(parlour.start_date)
              .local()
              .format("MMM DD, h:mma")}{" "}
            EST
          </span>
        </div>
        <div className="parlour-video">
          <img
            className="parlour-icon_video"
            src={iconVideo}
            alt="video_icon"
            loading="lazy"
          />
        </div>
      </div>
      <div className="card-participants">
        <div className="card-participant">
          <h3 className="card-participant_title">Host:</h3>
          <div className="card-participant_detail">
            <img
              className="card-participant_photo"
              src={parlour.owner.profile_image_url}
              alt={parlour.owner.name}
              loading="lazy"
            />
            <span className="card-participant_name">
              {parlour.owner.name}
            </span>
          </div>
        </div>
        {parlour.guest && (
          <div className="card-participant">
            <h3 className="card-participant_title">Guest:</h3>
            <div className="card-participant_detail">
              <img
                className="card-participant_photo guest"
                src={parlour.guest.profile_image_url}
                alt={parlour.guest.name}
                loading="lazy"
              />
              <span className="card-participant_name">
                {parlour.guest.name}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="card-actions">
        <FormGroup className="customswitch">
          <CustomInput
            type="switch"
            id={`switch-parlour-${parlour.id}`}
            name={`switch-parlour-${parlour.id}`}
            value={parlour.is_public}
            checked={parlour.is_public}
            onChange={() => handlerUpdateParlour('is_public')}
            label="Publish in the App?"
          />
        </FormGroup>
        {
          !parlour.live && (
            <FormGroup className="customswitch">
              <CustomInput
                type="switch"
                id={`switch-community-${parlour.id}`}
                name={`switch-community-${parlour.id}`}
                value={parlour.community_tab}
                checked={parlour.community_tab}
                onChange={() => handlerUpdateParlour('community_tab')}
                label="Show on the Discover tab"
              />
            </FormGroup>
          )
        }
        <button
          disabled={loading}
          className="btn btn-primary"
          onClick={() => getParlourParticipants(parlour.id)}
        >
          All RSVP
        </button>
        <button
          disabled={loading}
          className="btn delete-parlour"
          onClick={() => handleDeleteParlour(parlour.id)}
        >
          Delete Parlour
        </button>
      </div>
    </div>
  )
}

export default connect(null, {
  updateSchedulerParlour,
})(withRouter(CardParlour));