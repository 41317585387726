import React from "react";
import siteLogo from "../../../assets/images/logo.svg";
import { Link, NavLink, withRouter } from "react-router-dom";
import "./sideBar.scss";
import { connect } from "react-redux";

const SideBar = (props) => {
  const routeTab = async () => {
    
  };

  return (
    <aside className="sidebar">
      <Link className="site-logo" to="/">
        <img src={siteLogo} width="75" height="75" alt="site logo" />
      </Link>
      <ul className="navigaiton">
        <li onClick={() => routeTab()}>
          <NavLink className="dashboard" exact to="/dashboard">
            Dashboard
          </NavLink>
        </li>
        <li onClick={() => routeTab()}>
          <small>User Management</small>
          <NavLink className="user" to="/user-list">
            User Listing
          </NavLink>
        </li>
        <li onClick={() => routeTab()}>
          <small>Post Management</small>
          <NavLink className="post" to="/bulletin-board">
            Bulletin Board
          </NavLink>
        </li>
        <li onClick={() => routeTab()}>
          <small>Podcast Management</small>
          <NavLink className="podcast" to="/podcast-management">
            Podcast Management
          </NavLink>
        </li>
        <li onClick={() => routeTab()}>
          <small>Headline Management</small>
          <NavLink className="headline" to="/headline-management">
            Headline Management
          </NavLink>
        </li>
        <li onClick={() => routeTab()}>
          <small>Song Contest</small>
          <NavLink className="voteSong" to="/song-contest">
            Song Contest
          </NavLink>
        </li>
        <li onClick={() => routeTab()}>
          <small>Scheduler Parlour</small>
          <NavLink className="parlour" to="/scheduler-parlour">
            Scheduler Parlour
          </NavLink>
          <NavLink className="parlour" to="/sell-parlour">
            Finished Parlours
          </NavLink>
        </li>
        <li onClick={() => routeTab()}>
          <small>Support Management</small>
          <NavLink className="support" to="/support-management">
            Support
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

const mapStateToProps = (store) => {
  return {
    admin: store.user,
  };
};

export default connect(mapStateToProps, {})(withRouter(SideBar));
