import React, { Fragment } from "react";
import DataTable from "react-data-table-component";
import DummyIcon from "../../../assets/images/user.jpg";
import { Input, FormGroup, Button } from "reactstrap";
import ModalBox from "../../../components/shared/pageComponent/ModalBox";
import FilterForm from "./FilterForm";
import { NavLink, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import NotificationMessage from "../../../components/shared/pageComponent/NotificationMessage";
import { connect } from "react-redux";
import {
  userList,
  ChangeUserStatus,
  userDetail,
  searchUser,
  filterUser,
  sortUserList,
  deleteUser,
} from "../../../api/admin";
import { getUsersTalents } from "../../../api/users";
import { Numbers, Text } from "../../../constants/constant";
import Pagination from "react-js-pagination";
import moment from "moment";

class UsersListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isNotificationOpen: false,
      loadDelete: false,
      userLists: [],
      page: 1,
      userData: "",
      status: "",
      toggleActionButton: false,
      userDetails: {},
      searchValue: "",
      activePage: 1,
      limit: 10,
      talentList: [],
      city: null,
      state: null,
      zipcode: null,
      start_date: null,
      end_date: null,
      profileType: null,
      selectedTalents: [],
      selectedProfileType: null,
      isDisabled: true,
      isApply: false,
      sort: false,
      sortBy: null,
    };
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.props.setTitlePage("User Management");
    let search_data =
      this.props.location.search &&
      this.props.location.search.split("?search=");
    if (search_data) {
      this.getUsersTalents();
      let flag;
      if (search_data[1] === "artist") {
        flag = [
          {
            label: "Artist",
            value: 1,
          },
        ];
        this.setState({ selectedTalents: flag }, () => {
          this.applyFilter();
        });
      } else if (search_data[1] === "producer") {
        flag = [
          {
            label: "Producer",
            value: 2,
          },
        ];
        this.setState({ selectedTalents: flag }, () => {
          this.applyFilter();
        });
      } else if (search_data[1] === "instrument") {
        flag = [
          {
            label: "Instrumentalist",
            value: 3,
          },
        ];
        this.setState({ selectedTalents: flag }, () => {
          this.applyFilter();
        });
      } else if (search_data[1] === "audio") {
        flag = [
          {
            label: "Audio Engineer",
            value: 4,
          },
        ];
        this.setState({ selectedTalents: flag }, () => {
          this.applyFilter();
        });
      } else if (search_data[1] === "songwriter") {
        flag = [
          {
            label: "Songwriter",
            value: 5,
          },
        ];
        this.setState({ selectedTalents: flag }, () => {
          this.applyFilter();
        });
      } else {
        this.setState({ selectedProfileType: "Featured" }, () => {
          this.applyFilter();
        });
      }
    } else {
      this.getUserList();
    }
  }

  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  changeStatus = (stat, id) => {
    if (id) {
      this.setState({ userData: id });
    }
    this.setState({
      status: stat,
      isNotificationOpen: !this.state.isNotificationOpen,
    });
  };

  notify = async (a) => {
    this.props.setLoading(true);
    const isSuccess = await this.props.ChangeUserStatus(
      this.state.userData,
      this.state.status
    );
    this.props.setLoading(false);
    if (isSuccess) {
      toast(isSuccess.msg);
      this.getUserList();
    } else {
      toast("There is something wrong!", { type: "error" });
    }
  };

  dataUpdate = (user) => {
    user.state = user.state ? `${user.state} ` : "N/A";
    user.city = user.city ? `${user.city}, ` : "";
    user.featured = user.featured ? Text.featured : Text.non_featured;
    user.active = user.active ? "Active" : "Inactive";
    user.location = `${user.city} ${user.state}`;
    user.talent = "";
    if (user && user.roles && user.roles.length) {
      user.roles.forEach((element, keys) => {
        user.talent += `${element.title}${
          user.roles.length - Numbers.one === keys ? "" : ", "
        }`;
      });
    }
    return user;
  };

  getUserList = async (
    sortBy = this.state.sortBy,
    sortDir = this.state.sort
  ) => {
    const userListData = await this.props.userList(
      this.state.activePage,
      this.state.limit,
      sortBy,
      sortDir
    );
    if (userListData && userListData.data) {
      userListData.data.forEach(async (user) => {
        await this.dataUpdate(user);
      });
      this.setState({
        totalCounts: userListData.totalCounts,
        userLists: userListData,
      });
      this.getUsersTalents();
    } else {
      this.props.setLoading(false);
      this.setState({ userLists: [] });
    }
  };

  onSort = async (e) => {
    let {
      searchValue,
      city,
      state,
      zipcode,
      selectedProfileType,
      selectedTalents,
    } = this.state;
    this.setState(
      {
        sort: !this.state.sort,
        sortBy: e.selector,
      },
      async () => {
        if (searchValue) {
          this.searchUser({
            e: searchValue,
            searchByPage: true,
            sortBy: e.selector,
            sortDir: this.state.sort,
          });
        } else if (
          city ||
          state ||
          zipcode ||
          selectedProfileType ||
          selectedTalents.length
        ) {
          this.applyFilter({
            isModal: false,
            is_apply: false,
            sortBy: e.selector,
            sortDir: this.state.sort,
          });
        } else {
          this.getUserList(e.selector, this.state.sort);
        }
      }
    );
    this.props.setLoading(true);
  };

  getUsersTalents = async () => {
    const talent = await this.props.getUsersTalents();
    if (talent) {
      let tal = [];
      talent.forEach((element) => {
        tal.push({ value: element.id, label: element.title });
      });
      this.setState({ talentList: tal });
    }
    this.props.setLoading(false);
  };

  showActionButton = (id, event) => {
    const htmlData = document.getElementById(id);
    htmlData.classList.add("childAction");
    event.target.className += " action-wrap-two";
  };

  searchUser = async (args) => {
    let {
      e,
      searchByPage = false,
      sortBy = this.state.sortBy,
      sortDir = this.state.sort,
      pageNumber = 1,
    } = args;
    const value = searchByPage ? e : e.target.value;
    this.setState({ searchValue: value, activePage: pageNumber });
    if (value.length > Numbers.two) {
      this.props.setLoading(true);
      let userListData = await this.props.searchUser(
        value,
        pageNumber,
        this.state.limit,
        sortBy,
        sortDir
      );
      if (userListData && userListData.data) {
        userListData.data.forEach(async (user) => {
          await this.dataUpdate(user);
        });
        this.setState({
          totalCounts: userListData.totalCounts,
          userLists: userListData,
        });
        this.props.setLoading(false);
      } else {
        this.setState({ userLists: [] });
        this.props.setLoading(false);
      }
    } else if (value.length === 0) {
      if (this.state.selectedTalents) {
        this.applyFilter();
      } else {
        this.getUserList();
      }
    }
  };

  applyFilter = async (args = {}) => {
    let {
      isModal,
      is_apply = true,
      sortBy = this.state.sortBy,
      sortDir = this.state.sort,
      pageNumber,
    } = args;
    pageNumber = pageNumber ? pageNumber : this.state.activePage;
    const { city, state, zipcode, selectedProfileType, selectedTalents, start_date, end_date } =
      this.state;
    if (
      !city &&
      !state &&
      !zipcode &&
      !start_date &&
      !end_date &&
      !selectedProfileType &&
      selectedTalents &&
      !selectedTalents.length
    ) {
      this.setState({ isDisabled: true });
      this.props.setLoading(true);
      this.getUserList();
      return;
    }
    this.setState({ searchValue: "" });
    this.props.setLoading(true);
    let roleId = [];
    if (selectedTalents && selectedTalents.length) {
      selectedTalents.forEach((element) => {
        roleId.push(element.value);
      });
    }
    const dataUser = await this.props.filterUser({
      city,
      state,
      zipcode,
      roleIds: roleId,
      profile_type: selectedProfileType,
      start_date,
      end_date,
      page: pageNumber,
      limit: this.state.limit,
      sortBy,
      sortDir,
    });
    if (dataUser && dataUser.data) {
      dataUser.data.forEach(async (user) => {
        await this.dataUpdate(user);
      });
      this.setState({
        isApply: is_apply,
        totalCounts: dataUser.totalCounts,
        userLists: dataUser,
      });
      this.props.setLoading(false);
      isModal && this.toggleModal();
    } else {
      this.setState({ userLists: [] });
      this.props.setLoading(false);
    }
  };

  handlePageChange(pageNumber) {
    const {
      city,
      state,
      zipcode,
      start_date,
      end_date,
      selectedProfileType,
      selectedTalents,
      searchValue,
    } = this.state;
    this.setState({ activePage: pageNumber }, () => {
      if (searchValue) {
        this.searchUser({ e: searchValue, searchByPage: true, pageNumber });
      } else if (
        !city &&
        !state &&
        !zipcode &&
        !start_date &&
        !end_date &&
        !selectedProfileType &&
        selectedTalents &&
        !selectedTalents.length
      ) {
        this.props.setLoading(true);
        this.getUserList();
        return;
      } else {
        this.applyFilter();
      }
    });
    this.props.setLoading(true);
  }

  clearFilter = () => {
    this.setState(
      {
        city: null,
        state: null,
        zipcode: null,
        start_date: null,
        end_date: null,
        selectedTalents: null,
        selectedProfileType: null,
        activePage: 1,
      },
      () => {
        this.getUserList();
      }
    );
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value, isDisabled: false });
  };

  selectTalents = (val) => {
    this.setState({ selectedTalents: val, isDisabled: false });
  };

  selectProfiles = (val) => {
    this.setState({ selectedProfileType: val.target.value, isDisabled: false });
  };

  dataHandle = (data, flag) => {
    const { selectedTalents } = this.state;
    if (flag === "talent") {
      selectedTalents.length &&
        selectedTalents.forEach((element, index) => {
          if (element.value === data.value) {
            selectedTalents.splice(index, 1);
          }
        });
    } else if (flag === "city") {
      this.setState({ city: null });
    } else if (flag === "state") {
      this.setState({ state: null });
    } else if (flag === "zipcode") {
      this.setState({ zipcode: null });
    } else if (flag === "profileType") {
      this.setState({ selectedProfileType: null });
    } else {
      this.setState({
        selectedProfileType: null,
        city: null,
        state: null,
        zipcode: null,
        selectedTalents: null,
        isApply: false,
        activePage: 1,
      });
    }
    return;
  };

  handleFilter = async (data, flag) => {
    await this.dataHandle(data, flag);
    this.applyFilter({
      isModal: false,
      is_apply: flag === "clear" ? false : true,
      pageNumber: 1,
    });
  };

  profileType = () => {
    const { selectedProfileType } = this.state;
    return (
      <span className="tags">
        {selectedProfileType}{" "}
        <span
          className="cross"
          onClick={() => this.handleFilter(selectedProfileType, "profileType")}
        >
          remove
        </span>
      </span>
    );
  };

  cityType = () => {
    const { city } = this.state;
    return (
      <span className="tags">
        {city}{" "}
        <span className="cross" onClick={() => this.handleFilter(city, "city")}>
          remove
        </span>
      </span>
    );
  };

  stateType = () => {
    const { state } = this.state;
    return (
      <span className="tags">
        {state}{" "}
        <span
          className="cross"
          onClick={() => this.handleFilter(state, "state")}
        >
          remove
        </span>
      </span>
    );
  };

  searchTitle = () => {
    const {
      city,
      state,
      zipcode,
      selectedTalents,
      selectedProfileType,
      isApply,
    } = this.state;
    if (
      city ||
      state ||
      zipcode ||
      selectedProfileType ||
      (selectedTalents && selectedTalents.length)
    ) {
      return (
        <Fragment>
          {isApply && (
            <div className="searched-filters">
              {selectedTalents && selectedTalents.length
                ? selectedTalents.map((value, key) => (
                    <span className="tags" key={key}>
                      {value.label}
                      <span
                        className="cross"
                        onClick={() => this.handleFilter(value, "talent")}
                      >
                        remove
                      </span>
                    </span>
                  ))
                : ""}
              {selectedProfileType && this.profileType()}
              {city && this.cityType()}
              {state && this.stateType()}
              {zipcode && (
                <span className="tags">
                  {zipcode}
                  <span
                    className="cross"
                    onClick={() => this.handleFilter(zipcode, "zipcode")}
                  >
                    remove
                  </span>
                </span>
              )}
              {city ||
              state ||
              zipcode ||
              selectedProfileType ||
              (selectedTalents && selectedTalents.length) ? (
                <button
                  type="button"
                  className="text-btn"
                  onClick={() => this.handleFilter(zipcode, "clear")}
                >
                  Clear
                </button>
              ) : (
                ""
              )}
            </div>
          )}
        </Fragment>
      );
    }
    return "";
  };

  removeSearch = async () => {
    this.setState({ searchValue: "" });
    this.props.setLoading(true);
    const userListData = await this.props.searchUser(
      "",
      1,
      this.state.limit,
      this.state.sortBy,
      this.state.sort
    );
    if (userListData && userListData.data) {
      userListData.data.forEach(async (user) => {
        await this.dataUpdate(user);
      });
      this.setState({
        totalCounts: userListData.totalCounts,
        userLists: userListData,
      });
      this.props.setLoading(false);
    } else {
      this.setState({ userLists: [] });
      this.props.setLoading(false);
    }
  };

  handleDelete = async (id) => {
    confirmAlert({
      title: "Confirm to delete user",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              this.setState({ loadDelete: true });
              const response = await this.props.deleteUser(id);
              await this.getUserList();
              toast(response.msg, { type: "success" });
            } catch (error) {
              toast("Failed to delete user", { type: "error" });
            } finally {
              this.setState({ loadDelete: false });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    let {
      userLists,
      searchValue,
      totalCounts,
      limit,
      activePage,
      city,
      state,
      zipcode,
      start_date,
      end_date,
      profileType,
      selectedTalents,
      talentList,
      selectedProfileType,
      isDisabled,
    } = this.state;
    const columns = [
      {
        name: "Name & Email",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <div className="user-wrapper">
            <NavLink className="link-wrap" to={`/user-detail/${row.id}`}>
              <img
                height="33"
                width="33"
                alt={row.name}
                src={row.profile_image ? row.profile_image : DummyIcon}
              />
              <span className="user-detail">
                <span className="name">{row.name}</span>
                <span className="email-wrap">{row.email}</span>
              </span>
            </NavLink>
          </div>
        ),
      },
      {
        name: "Talent",
        selector: "talent",
        wrap: true,
      },
      {
        name: "Profile",
        selector: "featured",
        wrap: true,
      },
      {
        name: "Location",
        selector: "location",
        wrap: true,
      },
      {
        name: "No. of Podcast",
        selector: "",
        center: true,
        wrap: true,
        cell: (row) => (
          <div className="user-wrapper">
            {row.podcasts && row.podcasts.length ? row.podcasts.length : "N/A"}
          </div>
        ),
      },
      {
        name: "Total Spent",
        selector: "totalSpent",
        center: true,
      },
      {
        name: 'Created At',
        selector: 'createdAt',
        center: true,
        wrap: true,
        cell: (row) => (
          moment(row.createdAt).format("YYYY-MM-DD")
        )
      },
      {
        name: "Status",
        selector: "active",
        center: true,
        cell: (row) =>
          row.active === "Active" ? (
            <div>
              <span className="active-label">{row.active}</span>
            </div>
          ) : (
            <div>
              <span className="inactive">{row.active}</span>
            </div>
          ),
      },
      {
        selector: "action",
        cell: (row) => (
          <div>
            <div
              className="action-wrap"
              onClick={this.showActionButton.bind(this, row.id)}
            >
              <div id={row.id} className="child-wrap">
                {row.active === "Active" ? (
                  <span
                    className="item inactive"
                    onClick={() => this.changeStatus("inactive", row.id)}
                  >
                    Inactive
                  </span>
                ) : (
                  <span
                    className="item active-label"
                    onClick={() => this.changeStatus("active", row.id)}
                  >
                    Active
                  </span>
                )}
                <span
                  className="item"
                  onClick={() => this.handleDelete(row.id)}
                >
                  Delete
                </span>
              </div>
              <span className="dots-btn">dots</span>
            </div>
          </div>
        ),
      },
    ];
    return (
      <div>
        <div className="data-search-bar">
          <h3>User Listing hey</h3>
          <div className="right-bar">
            <FormGroup className="search-wrap">
              <Input
                type="text"
                value={searchValue}
                name="datasearch"
                onChange={(value) => this.searchUser({ e: value })}
                id="datasearch"
                placeholder="Search by name and location"
              />
              {searchValue && (
                <span className="search-cross" onClick={this.removeSearch}>
                  X
                </span>
              )}
            </FormGroup>
            <Button
              onClick={this.toggleModal}
              outline
              color="secondary"
              className="filter-btn"
            >
              Filter
            </Button>
            <ModalBox
              isOpen={this.state.isModalOpen}
              withHeader={true}
              ModalTitle="Filter"
              toggle={this.toggleModal}
              className="modal-right"
            >
              <FilterForm
                handleChange={this.handleChange}
                applyFilter={() =>
                  this.applyFilter({ pageNumber: 1, isModal: true })
                }
                clearFilter={this.clearFilter}
                talentLists={talentList}
                city={city}
                state={state}
                zipcode={zipcode}
                start_date={start_date}
                end_date={end_date}
                profileType={profileType}
                selectedTalent={selectedTalents}
                selectTalents={this.selectTalents}
                selectProfile={this.selectProfiles}
                selectedProfileType={selectedProfileType}
                isDisabled={isDisabled}
              />
            </ModalBox>
          </div>
        </div>
        {this.searchTitle()}
        <DataTable
          columns={columns}
          className="custom-table user-table"
          data={userLists && userLists.data}
          onSort={this.onSort}
        />
        <ModalBox
          isOpen={this.state.isNotificationOpen}
          ModalTitle="Filter"
          toggle={this.changeStatus}
          className="modal-small"
        >
          <NotificationMessage
            openNot={this.notify}
            closeModal={this.changeStatus}
            userAction={
              this.state.status === "active" ? "Activate" : "Inactivate"
            }
            item="user"
          />
        </ModalBox>
        {totalCounts > 10 && (
          <>
            <p><strong>Total rows:</strong> {totalCounts}</p>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={limit}
              totalItemsCount={totalCounts}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    admin: store.user,
  };
};

export default connect(mapStateToProps, {
  userList,
  userDetail,
  ChangeUserStatus,
  searchUser,
  getUsersTalents,
  filterUser,
  sortUserList,
  deleteUser,
})(withRouter(UsersListing));
