import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSellParlourDetail, getTransactionsSellParlour } from '../../api/scheduler-parlour';
import Pagination from 'react-js-pagination';

function DetailParlour(props) {
  const [sellParlour, setSellParlour] = React.useState(null);
  const [dataTable, setDataTable] = React.useState({
    data: [],
    pagination: {
      totalRecords: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 5,
    }
  });

  const getTransactions = async (page = 1) => {
    try {
      props.setLoading(true);
      const data = await props.getTransactionsSellParlour({
        id: sellParlour.id, page, pageSize: dataTable.pagination.pageSize
      });
      setDataTable(data);
    } finally {
      props.setLoading(false);
    }
  };

  const convertDurationToMinutes = (milisecon) => {
    const seconds = milisecon / 1000;
    if (seconds < 60) {
      return `${Math.round(seconds)} sec`;
    } else if (seconds < 3600) {
      return `${Math.floor(seconds / 60)} min`;
    } else {
      return `${(seconds / 3600).toFixed(1)} hour`;
    }
  };

  React.useEffect(() => {
    const id = props.match.params.id;
    
    props.getSellParlourDetail(id).then(setSellParlour);
  }, []);

  React.useEffect(() => {
    if (sellParlour) {
      props.setTitlePage(sellParlour.title);
      getTransactions();
    } else {
      props.setTitlePage('Loading...');
    }
  }, [sellParlour]);
  
  return sellParlour ? (
    <>
      <p>{sellParlour.description}</p>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <strong>Price: </strong> ${sellParlour.price}
              </td>
              <td>
                <strong>Duration: </strong> {convertDurationToMinutes(sellParlour.duration)}
              </td>
              <td>
                <strong>Start Date: </strong> 
                { new Date(sellParlour.scheduler_parlour.start_date).toLocaleString() }
              </td>
            </tr>
            <tr>
              <td>
                <strong>Author: </strong> {sellParlour.user.name}
              </td>
              <td>
                <strong>Reviews: </strong> {sellParlour.num_reviews}
              </td>
              <td>
                <strong>Participants: </strong> {sellParlour.num_participants}
              </td>
            </tr>
            <tr>
              <td>
                <strong className='text-primary'>Collected: </strong> ${sellParlour.collected}
              </td>
              <td>
                <strong className='text-danger'>Net Profit: </strong> ${sellParlour.netProfit}
              </td>
              <td>
                <strong className='text-success'>Revenue: </strong> ${sellParlour.revenue}
              </td>
            </tr>
            <tr>
              <td>
                <img src={sellParlour.user.profile_image_url} width={200} alt={sellParlour.user.name} />
                <p>{sellParlour.user.email}</p>
              </td>
              <td colSpan={2}>
                <video width="520" height="240" poster={sellParlour.scheduler_parlour.poster_url} controls>
                  <source src={sellParlour.video_url} type="video/mp4" />
                </video>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="my-3">
        <h4>
          Transactions &nbsp;
          <small style={{ fontSize: '13px'}}>({dataTable.pagination.totalRecords})</small>
        </h4>
      </div>
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>User</th>
              <th>Amount</th>
              <th>Purchased</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              dataTable.data.map((row, index) => (
                <tr key={`tr-${row.id}`}>
                  <th className='text-center' style={{verticalAlign: 'middle'}}>
                    <img src={row.user.profile_image_url} width={60} className="img-thumbnail" alt={row.user.name} loading="lazy" />
                  </th>
                  <td style={{verticalAlign: 'middle'}}>{row.user.name}</td>
                  <td style={{verticalAlign: 'middle'}}>${row.amount / 100}</td>
                  <td style={{verticalAlign: 'middle'}}>
                    { new Date(row.created_at).toLocaleString() }
                  </td>
                  <td style={{verticalAlign: 'middle'}}>{row.status}</td>
                </tr>
              ))
            }
            {
              dataTable.data.length === 0 && (
                <tr>
                  <td colSpan={10} className='text-center p-4'>No data available</td>
                </tr>
              )
            }
          </tbody>
        </table>
        <Pagination
          activePage={dataTable.pagination.currentPage}
          itemsCountPerPage={dataTable.pagination.pageSize}
          totalItemsCount={dataTable.pagination.totalRecords}
          pageRangeDisplayed={5}
          onChange={getTransactions}
        />
      </div>
      
    </>
  ) : null
}


export default connect(null, {
  getSellParlourDetail,
  getTransactionsSellParlour
})(withRouter(DetailParlour));