import React, { useState, useEffect, useRef } from 'react'
import {
  Fade, ListGroup, ListGroupItem
} from 'reactstrap';
import bellIcon from '../../../assets/images/bell.svg';
import { connect } from 'react-redux';
import { getNotificationList } from '../../../api/admin';
import { Numbers } from '../../../constants/constant'
import moment from 'moment';

const Notification = (props) => {
  const hasFetched = useRef(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [page, setPage] = useState(Numbers.one);
  const [notifications, setNotifications] = useState([]);
  const notificationRow = []
  
  async function fetchDetails(read) {
    const notification = await props.getNotificationList(page, Numbers.ten, read);
    setNotifications(prevState => [...prevState, notification]);
  }

  useEffect(() => {
    if (!hasFetched.current) {
      fetchDetails();
      hasFetched.current = true;
    }
  }, []);

  const createMarkup = (message) => {
    return { __html: message };
  }

  const viewMore = () => {
    setPage((old) => old + 1);
  }

  const toggleFade = () => {
    setFadeIn(!fadeIn);
  }

  const checkClass = () => {
    return props && props.userNotification && props.userNotification.notification;
  }

  const getTimeDate = (date) => {
    const tempDate = moment(date);
    const currentDate = moment();
    const nextDate = moment(tempDate).add(1, 'd');
    if (tempDate === currentDate) return moment(date).format("hh:mm a");
    if (currentDate === nextDate) return `1 day ago`;
  }

  return (
    <div className="notification-bar">
      {checkClass() && <span className={props.userNotification.notification.un_read ? 'notification-btn new' : 'notification-btn'} href="/" onClick={toggleFade}>
        <img src={bellIcon} alt="notification" width="24" height="24" />
      </span>}
      {props && props.userNotification && props.userNotification.notification && fadeIn && <Fade in={fadeIn} tag="div" className="mt-3">
        <ListGroup>
          {notifications.forEach(notification => {
            notification.rows.map(res => notificationRow.push(res))
          })}
          {notificationRow.length ? notificationRow.map((notification, key) =>
            <ListGroupItem className={notification.read ? '' : 'new'} key={key}>
              <div className="inner">
                <div dangerouslySetInnerHTML={createMarkup(notification.message)}></div>
                <div className='time-date' dangerouslySetInnerHTML={createMarkup(getTimeDate(notification.createdAt))}></div>
              </div>
            </ListGroupItem>)
            : ''}
        </ListGroup>
        {props.userNotification.notification.totalPage > page && <div className="view-more-wrap" onClick={viewMore}>
          <span className="view-btn">View More </span>
        </div>}
      </Fade>}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userNotification: state.notification
  }
}

export default connect(mapStateToProps, {
  getNotificationList
}
)(Notification);
