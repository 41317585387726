import React, { useState, useEffect } from 'react';
import {
  Breadcrumb, BreadcrumbItem, Media, Row, Col, Button
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import DummyIcon from '../../assets/images/user.jpg';
import './podcastDetail.scss';
import ModalBox from '../../components/shared/pageComponent/ModalBox';
import PodcastForm from './PodcastForm';
import { connect } from 'react-redux';
import {
  getPodcastDetails, userList, getAwsCredentials,
  updatePodcast, deleteMediaViaUrl, deletePodcast,
  searchUserByName
} from '../../api/admin';
import S3FileUpload from 'react-s3';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationMessage from '../../components/shared/pageComponent/NotificationMessage';

const PodcastDetail = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [podcastData, setPodcastData] = useState({});
  const [podcastes, setPodcasts] = useState(null);
  const [userlist, setUserList] = useState(null);
  const [user, setUser] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [name, setName] = useState(null);
  const [inputValue, setUserName] = useState('');
  const [awsCredential, setAwsCredentials] = useState(null);
  const [s3_audio_duration, setS3_audio_duration] = useState([]);
  const [s3_audio_key, setS3_audio_key] = useState([]);
  const [new_s3_audio_key, setS3_new_audio_key] = useState([]);
  const [podcastId, setPodcastId] = useState(null);
  const [is_show, setIsShow] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [deletePodcastId, setDeletePodcast] = useState();

  const toggleModal = () => setModalOpen(prevState => !prevState);

  const deletePodcasts = (id) => {
    if (id) {
      setDeletePodcast(id)
    }
    setIsNotificationOpen(open => !open);
  }
  const notifyPodcast = async (a) => {
    props.setLoading(true);
    const deletes = await props.deletePodcast(deletePodcastId);
    if (deletes && deletes.data) {
      fetchDetails()
      setDeletePodcast();
      props.setLoading(false);
      toast(deletes.data.msg);
    }
    props.setLoading(false);
  }

  const trackUrl = (data) => {
    let link = [];
    if (data && data.medias && data.medias.length) {
      data.medias.forEach((value) => {
        link.push(value.url);
      });
      setS3_audio_key(link);
    }
  }

  const editPodcats = async (data) => {
    setName(data.name);
    setIsShow(data.is_show);
    setPodcastData(data)
    setPodcastId(data.id)
    await trackUrl(data);
    setModalOpen(true);
  }

  const calculateDuration = (duration) => {
    if (duration) {
      return `${parseInt(duration / 60)}:${parseInt(duration % 60)}`
    }
    return '';
  }

  const podcastList = (podcast) => {
    if (podcast && podcast.length) {
      let podList = [];
      podcast.forEach((podcasts, keys) => {
        return podList.push(
          <div key={keys} className="child-div">
            <Row className="data-row">
              <Col xs="4" className="data-box">
                <strong className="label">Podcast Name</strong>
                <span className="data">{podcasts.name}</span>
              </Col>
              <Col xs="4" className="data-box">
                <strong className="label">Show Podcast</strong>
                <span className="data">{podcasts.is_show ? 'Yes' : 'No'}</span>
              </Col>
              <Col xs="4" className="data-box">
                <strong className="label">No. of Audio Files</strong>
                <span className="data">{podcasts.medias.length}</span>
              </Col>
            </Row>
            <ul className="tracks-wrapper">
              {podcasts.medias.length ? podcasts.medias.map((value, index) => {
                return (
                  <li key={index}>
                    <figure className="track-icon">
                      music icon
                      </figure>
                    <span className="title">{value.title ? value.title : `Track ${index + 1}`}</span>
                    <span className="time">{calculateDuration(parseInt(value.duration))}</span>
                  </li>
                )
              }) : ''}
            </ul>
            <div className="btn-row">
              <Button className="" onClick={() => editPodcats(podcasts)}>Edit</Button>
              <Button onClick={() => deletePodcasts(podcasts.id)}>Remove</Button>
            </div>
          </div>
        )
      });
      setPodcasts(podList);
    } else {
      return setPodcasts(<span>There is no any podcast.</span>)
    }
    return false;
  }

  async function fetchDetails() {
    props.setLoading(true);
    const userId = props.location.pathname.split('/podcast-detail/');
    let detail = await props.getPodcastDetails(userId[1]);
    const awsCredentials = await props.getAwsCredentials();
    setAwsCredentials(awsCredentials);
    if (detail && detail.data) {
      setUserId(detail.data.id);
      setDetails(detail.data);
      podcastList(detail.data.podcasts)
      setUserName(detail.data.name)
      setUser({
        value: detail.data.name,
        id: detail.data.id,
        label: <div className="list-with-icon">
          <span className="img-wrap">
            <img className="small-icon" src={detail.data.profile_image ? detail.data.profile_image : DummyIcon} alt="red" height="30px" width="30px" />
          </span>
          <div className="user-info">
            <strong className="user-name">{detail.data.name}</strong>
          </div>
        </div>
      })
    }
    props.setLoading(false);
  }

  useEffect(() => {
    props.setTitlePage('Podcast Management');
    fetchDetails();
  }, []);

  const roles = (role) => {
    if (role && role.length) {
      return role.map((value, keys) => <span className="talent" key={keys}>
        {value.title}{role.length === keys + 1 ? '' : ','}</span>
      )
    }
    return '';
  }

  const handleSelect = (e, item) => {
    if (e) {
      setUser(item);
      setUserId(item.id)
      setUserName(item.value)
    }
  };

  const searchInputChange = async (e) => {
    let tempInputValue = e.target.value
    await setUserName(tempInputValue)
    const usersList = await props.searchUserByName(tempInputValue);
    if (usersList && usersList.data) {
      let userslist = [];
      usersList.data.length && usersList.data.forEach((userData, key) => {
        userslist.push({
          value: userData.name,
          id: userData.id,
          label: <div className="list-with-icon" key={key}>
            <span className="img-wrap">
              <img className="small-icon" src={userData.profile_image ? userData.profile_image : DummyIcon} alt="red" height="30px" width="30px" />
            </span>
            <div className="user-info">
              <strong className="user-name">{userData.name}</strong>
              <small className="email">{userData.email}</small>
            </div>
          </div>
        })
      });
      setUserList(userslist)
    }
  };

  const handleChange = e => {
    const { value } = e.target;
    setName(value);
    if (e.target.value === "") {
      e.target.classList.add('error');
    } else {
      e.target.classList.remove('error');
    }
  };

  const getDuration = (data) => {
    let audio = document.createElement('audio');
    audio.src = data.location;
    audio.addEventListener('loadedmetadata', () => {
      var duration = audio.duration;
      setS3_audio_duration([...s3_audio_duration, `${parseInt(duration)}`])
    }, false);
  }

  const uploadAudioOns3 = (file) => {
    props.setLoading(true);
    const folder = `users/${user_id}/audio`
    const { bucket_name, aws_access_key_id, aws_secret_access_key } = awsCredential.s3
    const config = {
      bucketName: bucket_name,
      dirName: folder,
      region: 'us-east-1',
      accessKeyId: aws_access_key_id,
      secretAccessKey: aws_secret_access_key,
    }
    let fileObj = file.target.files[0]
    S3FileUpload
      .uploadFile(fileObj, config)
      .then(async (data) => {
        await getDuration(data);
        setS3_audio_key([...s3_audio_key, `/${data.key}`]);
        setS3_new_audio_key([...new_s3_audio_key, `/${data.key}`]);
        props.setLoading(false);
      })
      .catch(error => {
        props.setLoading(false);
        return error;
      })
  }

  const onFileChange = (file) => {
    const selectedFile = file.target.files[0];
    if (
      selectedFile.type.includes('wav') ||
      selectedFile.type.includes('mpeg') ||
      selectedFile.type.includes('mp3')
    ) {
      const date = new Date();
      Object.defineProperty(file.target.files[0], 'name', {
        writable: true,
        value: `${date.getTime()}_${file.target.files[0].name.replaceAll(' ', '')}`
      });
      if (!user_id) {
        toast('Please select user!', { type: 'error' });
        return false;
      } else if (s3_audio_key && s3_audio_key.length >= 3) {
        toast('You can upload only three audio track in a podcast', { type: 'error' });
        return false;
      }
      else {
        uploadAudioOns3(file);
      }
    } else {
      file.target.value = '';
      return toast('You can upload only mp3 or wav file', { type: 'error' });
    }
    return false;
  }

  const handleCustom = e => {
    setIsShow(!is_show);
  };

  const updatePodcasts = async () => {
    if (!name && !user_id && s3_audio_key && !s3_audio_key.length) {
      return;
    } else if (!name) {
      toast('Please enter podcast name!', { type: 'error' });
      return;
    } else if (!user_id) {
      toast('Please select a user!', { type: 'error' });
      return;
    } else if (s3_audio_key && !s3_audio_key.length) {
      toast('Please upload atleast one audio track!', { type: 'error' });
      return;
    }
    props.setLoading(true);
    const data = {
      "name": name,
      "user_id": user_id,
      "is_show": is_show,
      "audio_url": []
    }
    if (new_s3_audio_key && new_s3_audio_key.length) {
      new_s3_audio_key.forEach((url, key) => {
        data.audio_url.push({
          "url": url,
          "duration": s3_audio_duration[key],
          "thumbnail": null,
          "title": url.split('/audio/')[1]
        })
      })
    }
    const update = await props.updatePodcast(podcastId, data);
    if (update && update.status === 201) {
      setName('');
      setUserId(null);
      setUser('');
      setIsShow(false);
      setModalOpen(false);
      setS3_audio_key([]);
      setS3_new_audio_key([]);
      props.history.push('/podcast-detail/' + user_id);
      toast('Podcast update successfully!', { autoClose: 3000 });
    } else if (update && update.data) {
      toast(update.data, { type: 'error' });
    }
    props.setLoading(false);
  }

  const getTrackId = (url) => {
    let Ids = url.substring(1);
    podcastData.medias.forEach((data) => {
      if (data.url === url) {
        Ids = data.id;
      }
    });
    return Ids;
  }

  const deleteMediaFromS3 = async (url) => {
    props.setLoading(true);
    let Ids = await getTrackId(url);
    const deletes = await props.deleteMediaViaUrl(Ids);
    if (deletes && deletes.status === 200) {
      var index = s3_audio_key.indexOf(url);
      var index1 = new_s3_audio_key.indexOf(url)
      s3_audio_key.splice(index, 1);
      if (index1 >= 0)
        new_s3_audio_key.splice(index1, 1);
      toast('Track deleted successfully!');
    } else {
      toast('Something went wrong.', { type: 'error' });
    }
    props.setLoading(false);
  }

  return details ? (
    <>
      <div className="header-section">
        <div className="title-wrap">
          <h3>Podcasts Details</h3>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/user-list">Manage Podcasts</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Podcast Details</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <div className="white-bg-row wide pod">
        <div className="justify-between">
          <Media className="align-center">
            <Media className="wide" left href="#">
              <Media width="80" height="80" object src={details.profile_image ? details.profile_image : DummyIcon} alt="Generic placeholder image" />
            </Media>
            <Media body>
              <Media heading>
                {details.name}
                <span className="highlight">
                  {details.featured ? 'Featured' : 'Non-Featured'}
                </span>
              </Media>
              {roles(details.roles)}
            </Media>
          </Media>
          <div className="pod-count">
            <strong className="count">{details.podcasts && details.podcasts.length}</strong>
            <small className="label">No. of Podcast(s)</small>
          </div>
        </div>
        {podcastes}
        <ModalBox
          isOpen={isModalOpen}
          ModalTitle="Update Podcast"
          withHeader={true}
          toggle={toggleModal}
          className="modal-right">
          {podcastData && <PodcastForm name={podcastData.name}
            userId={podcastData.user_id}
            is_show={is_show}
            userLists={userlist}
            user={user}
            handleSelect={handleSelect}
            handleChange={handleChange}
            name={name}
            onFileChange={onFileChange}
            s3_audio_key={s3_audio_key}
            handleCustom={handleCustom}
            searchInputChange={searchInputChange}
            title="Update"
            updatePodcast={updatePodcasts}
            removeTrack={deleteMediaFromS3}
            inputValue={inputValue}
          />}
        </ModalBox>
      </div>
      <ModalBox
        isOpen={isNotificationOpen}
        ModalTitle="Filter"
        toggle={deletePodcasts}
        className="modal-small">
        <NotificationMessage
          openNot={notifyPodcast}
          closeModal={deletePodcasts}
          userAction="remove"
          item="podcast"
        />
      </ModalBox>
    </>
  ) : null
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  getPodcastDetails,
  userList,
  getAwsCredentials,
  updatePodcast,
  deleteMediaViaUrl,
  deletePodcast,
  searchUserByName
})(withRouter(PodcastDetail));
