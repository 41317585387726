import React from 'react';
import {
  Route,
  Redirect,
  withRouter
} from 'react-router-dom'
import DashboardStructure from '../components/shared/DashboardStructure/DashboardStructure';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const token = localStorage.getItem('token');
  const [loading, setLoading] = React.useState(false);
  const [titlePage, setTitlePage] = React.useState('');

  return (
    <Route {...rest} render={(props) => (
      token
        ? (
          <DashboardStructure 
            history={props.history}
            loading={loading}
            setLoading={setLoading}
            title={titlePage}
          >
            <Component
              {...props} 
              loading={loading} 
              setLoading={setLoading} 
              setTitlePage={setTitlePage}
            />
          </DashboardStructure>
        )
        : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
    )} />
  )
}

export default withRouter(PrivateRoute);
