import React from 'react';
import {
  Breadcrumb, BreadcrumbItem, Media, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import DummyIcon from '../../assets/images/dummy-image.png';
import { connect } from 'react-redux';
import { dateFormat, timeFormat } from '../../utils/method';
import { userEmailReply } from '../../api/admin';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createMarkup(html) {
  return { __html: html };
}

class SubscriptionPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supportDetails: {},
      reply_message: ''
    };
  }

  componentDidMount() {
    this.props.setTitlePage('Support Management');
    this.setState({ supportDetails: this.props.userSupportData.userSupport });
  }

  emailReply = async () => {
    this.props.setLoading(true);
    const { subject, user: { name, email } } = this.state.supportDetails;
    const data = {
      name: name,
      email: email,
      subject: subject,
      message: this.state.reply_message
    }
    const email_reply = await this.props.userEmailReply(data);
    this.props.setLoading(false);
    if (email_reply && email_reply.data) {
      this.setState({ reply_message: '' });
      toast(email_reply.data);
    } else {
      toast('Something went wrong.', { type: 'error' });
    }
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
    if (e.target.value === "") {
      e.target.classList.add('error');
    } else {
      e.target.classList.remove('error');
    }
  };

  checkSrc = (supportDetails) => {
    return supportDetails.user && supportDetails.user.profile_image ? supportDetails.user.profile_image : DummyIcon;
  }

  render() {
    const { supportDetails, reply_message } = this.state;
    return supportDetails && (
      <div>
        <div className="header-section">
          <div className="title-wrap">
            <h3>Subject: {supportDetails.subject}</h3>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/support-management">Support</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Subject: {supportDetails.subject}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div className="white-bg-row wide">
          <div className="justify-between">
            <Media className="align-center small">
              <Media className="wide" left href="#">
                <Media width="80" height="80" object src={this.checkSrc(supportDetails)} alt="Generic placeholder image" />
              </Media>
              <Media body>
                <Media heading>
                  {supportDetails.user && supportDetails.user.name}
                </Media>
                <span className="date-time"> {dateFormat(supportDetails.createdAt)} | {timeFormat(supportDetails.createdAt)}</span>
              </Media>
            </Media>
            <div className="issue-description" dangerouslySetInnerHTML={createMarkup(supportDetails.message)}>
            </div>
          </div>
          <Form className="reply-form">
            <FormGroup>
              <Label for="exampleEmail">Reply</Label>
              <Input type="textarea" value={reply_message} name="reply_message" onChange={this.handleChange} id="reply_message" placeholder="Write your reply...." />
            </FormGroup>
            <FormGroup className="action-wrap text-right">
              <Button color="primary" className="width-auto" disabled={!reply_message || reply_message === ''} onClick={this.emailReply}>Send</Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.user,
    userSupportData: state.supports
  }
}

export default connect(
  mapStateToProps, {
  userEmailReply
}
)(withRouter(SubscriptionPlan));

