import React from "react";
import * as Solid from "@heroicons/react/24/solid";
import PreloadImage from "react-preload-image";
import "./style.scss";

export const ModalVotes = ({ playback, hideModal }) => {
  return (
    <div className="container-votes">
      <div className="body">
        <button className="close-modal" onClick={hideModal}>
          <Solid.XMarkIcon />
        </button>
        <div className="votes">
          <span className="count-votes">
            {playback.votesLogged.length}{" "}
            {playback.votesLogged.length > 1 ||
            playback.votesLogged.length === 0 ? (
              <>votes</>
            ) : (
              <>vote</>
            )}
          </span>
          <h2 className="title">
            Registered users who voted for ({playback.media.title})
          </h2>
          <div className="list">
            {playback.votesLogged.length === 0 && (
              <div className="empty">Not votes by logged.</div>
            )}
            {playback.votesLogged.map((user, index) => {
              return (
                <div key={index} className="vote">
                  <div className="cover">
                    {user.profile_image_url ? (
                      <PreloadImage
                        className="image"
                        src={user.profile_image_url}
                        alt={user.name}
                        lazy
                      />
                    ) : (
                      <span className="alias">
                        {user.name.charAt(0).toUpperCase()}
                      </span>
                    )}
                  </div>
                  <div className="info">
                    <h3 className="title">{user.name}</h3>
                    <p className="description">
                      {user.city}-{user.state}
                    </p>
                  </div>
                  <Solid.HeartIcon className="heart-icon" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="votes">
          <span className="count-votes">
            {playback.votesNotLogged.length}{" "}
            {playback.votesNotLogged.length > 1 ||
            playback.votesNotLogged.length === 0 ? (
              <>votes</>
            ) : (
              <>vote</>
            )}
          </span>
          <h2 className="title">
            Unregistered users who voted for ({playback.media.title})
          </h2>
          <div className="list">
            {playback.votesNotLogged.length === 0 && (
              <div className="empty">Not votes by not logged.</div>
            )}
            {playback.votesNotLogged.map((email, index) => {
              return (
                <div key={index} className="vote">
                  <div className="cover">
                    {email ? (
                      <span className="alias">
                        {email.charAt(0).toUpperCase()}
                      </span>
                    ) : (
                      <span className="alias">A</span>
                    )}
                  </div>
                  <div className="info">
                    <h3 className="title">{email}</h3>
                    <p className="description">Anonymous vote</p>
                  </div>
                  <Solid.HeartIcon className="heart-icon" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
