import React from 'react';
import userIcon from '../../assets/images/users-image.svg';
import artistIcon from '../../assets/images/artists.svg';
import audioIcon from '../../assets/images/audio-engineer.svg';
import featuredIcon from '../../assets/images/featured-members.svg';
import newsIcon from '../../assets/images/news.svg';
import producerIcon from '../../assets/images/producers.svg';
import instumentIcon from '../../assets/images/instrumentalist.svg';
import songwriterIcon from '../../assets/images/songwriter.svg';
import bulletinIcon from '../../assets/images/bulletin-icon.svg'
import { Card, CardImg, CardText, CardBody, CardTitle, Button, FormGroup, Input } from 'reactstrap'
import LineChart from './LineChart';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { dashboardStatics, dashboardGraphData } from '../../api/admin';
import moment from 'moment';

// Chart data add from here

const more_details = 'More Details';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedGraph: false,
      selectedValue: 'yearly',
      dashboardData: null,
      dashboardDataForRole: null,
      graphData: null,
      graphLabels: null,
      graphDataset: { data1: [], data2: []},
      graphLabelDates: null,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    };
  }

  getDashboardGraphData = (value = this.state.selectedValue) => {
    this.setState({ loadedGraph: true });
    this.props.dashboardGraphData({
      "filterType": value,
      ...(
        value === 'custom' ? {
          "start_date": this.state.start_date,
          "end_date": this.state.end_date
        } : {}
      )
    })
      .then(res => {
        const {data1, data2} = res.graphData;
        const labels = [];
        const datasets = { data1: [], data2: [] };
        const labelDates = [];
        data1.forEach(element => {
          labels.push(element.lable);
          labelDates.push(element.labelDate);
          if (element.amount !== null) {
            const newData = element.amount.toString().substring(1);
            datasets.data1.push(newData);
          } else {
            datasets.data1.push(element.amount);
          }
        });
        datasets.data2 = data2.map((item) => item.count);
        this.setState({
          graphLabels: labels,
          graphDataset: datasets,
          graphLabelDates: labelDates,
          graphData: res,
        });
      })
      .finally(() => {
        this.setState({ loadedGraph: false });
      });
  }

  handleChange = (e) => {
    if (this.state.loadedGraph) return;
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === 'selectedValue' && value !== 'custom') {
        this.getDashboardGraphData(value)
      } else {
        const newDate = value;
        if (
          (value === 'custom' && this.state.start_date && this.state.end_date) ||
          (name === 'start_date' && newDate && this.state.end_date) ||
          (name === 'end_date' && newDate && this.state.start_date)
        ) {
          this.getDashboardGraphData(
            value === 'custom' ? value : this.state.selectedValue
          );
        }
      }
    });
  }

  async componentDidMount() {
    this.props.setLoading(true);
    this.props.setTitlePage('Dashboard');
    const statics = await this.props.dashboardStatics();
    if (statics) {
      this.setState({
        dashboardData: statics,
        dashboardDataForRole: statics[1].map((item) => {
          let iconSrc = null;
          let pathUrl = null;
          if (item.title.toLowerCase().includes('artist')) {
            iconSrc = artistIcon;
            pathUrl = 'artist';
          }
          if (item.title.toLowerCase().includes('producer')) {
            iconSrc = producerIcon;
            pathUrl = 'producer';
          }
          if (item.title.toLowerCase().includes('audio engineer')) {
            iconSrc = audioIcon;
            pathUrl = 'audio';
          }
          if (item.title.toLowerCase().includes('instrumentalist')) {
            iconSrc = instumentIcon;
            pathUrl = 'instrument';
          }
          if (item.title.toLowerCase().includes('songwriter')) {
            iconSrc = songwriterIcon;
            pathUrl = 'songwriter';
          }
          return {
            ...item,
            iconSrc,
            pathUrl,
            title: `Total number of ${item.title}`
          };
        })
      })
    }
    this.getDashboardGraphData();
    this.props.setLoading(false)
  }

  moreDetails = (user_flag) => {
    if (user_flag === 'users') {
      this.props.history.push('/user-list');
    }else if (user_flag === 'headline') {
      this.props.history.push('/headline-management');
    } else if (user_flag === 'artist') {
      this.props.history.push('/user-list?search=artist');
    } else if (user_flag === 'producer') {
      this.props.history.push('/user-list?search=producer');
    } else if (user_flag === 'audio') {
      this.props.history.push('/user-list?search=audio');
    } else if (user_flag === 'instrument') {
      this.props.history.push('/user-list?search=instrument');
    } else if (user_flag === 'songwriter') {
      this.props.history.push('/user-list?search=songwriter')
    } else {
      this.props.history.push('/user-list?search=featured');
    }
  }

  render() {
    const { loadedGraph, dashboardData, dashboardDataForRole, graphLabels, graphDataset, graphLabelDates, graphData } = this.state;
    const membershipRevenue = graphData ? graphData.membershipRevenue.substring(1) : '';
    const totalUsers = graphData ? graphData.totalUsers : '';
    const chartData = {
      labels: graphLabels,
      datasets: [
        {
          fill: false,
          lineTension: 0.5,
          backgroundColor: '#921950',
          borderColor: '#921950',
          borderWidth: 2,
          data: graphDataset.data1,
          date: graphLabelDates,
          key: 'amount',
        },
        {
          fill: false,
          lineTension: 0.5,
          backgroundColor: '#200c95',
          borderColor: '#200c95',
          borderWidth: 2,
          data: graphDataset.data2,
          date: graphLabelDates,
          key: 'users',
        }
      ]
    }
    return (
      <>
        {dashboardData && (
          <div className="card-wrap">
            <Card>
              <CardImg width="50" height="50" src={userIcon} alt="Total number of registered users" />
              <CardBody>
                <CardTitle>{dashboardData[0] && dashboardData[0].registered_users}</CardTitle>
                <CardText>Total number of registered users</CardText>
                <Button className="more-detail" onClick={() => this.moreDetails('users')}>
                  {more_details}
                </Button>
              </CardBody>
            </Card>
            <Card>
              <CardImg width="50" height="50" src={newsIcon} alt="Total number of registered headlines" />
              <CardBody>
                <CardTitle>{dashboardData[0] && dashboardData[0].registered_headlines}</CardTitle>
                <CardText>Total number of registered headlines</CardText>
                <Button className="more-detail" onClick={() => this.moreDetails('headline')}>
                  {more_details}
                </Button>
              </CardBody>
            </Card>
            {
              dashboardDataForRole.map((item, index) => (
                <Card key={`Card-${index}-${item.role_id}`}>
                  <CardImg width="50" height="50" src={item.iconSrc} alt={item.title} />
                  <CardBody>
                    <CardTitle>{item.member_count}</CardTitle>
                    <CardText>{item.title}s</CardText>
                    <Button className="more-detail" onClick={() => this.moreDetails(item.pathUrl)}>
                      {more_details} - {item.pathUrl}
                    </Button>
                  </CardBody>
                </Card>
              ))
            }
            <Card>
              <CardImg width="50" height="50" src={featuredIcon} alt="Total number of Featured Members" />
              <CardBody>
                <CardTitle>{dashboardData[0] && dashboardData[0].total_featured}</CardTitle>
                <CardText>Total number of Featured Members</CardText>
                <Button className="more-detail" onClick={() => this.moreDetails('featured')}>
                  {more_details}
                </Button>
              </CardBody>
            </Card>
            <Card>
              <CardImg width="50" height="50" src={newsIcon} alt="Total number of News" />
              <CardBody>
                <CardTitle>{dashboardData[0] && dashboardData[0].total_headline_count}</CardTitle>
                <CardText>Total number of Featured Headlines</CardText>
              </CardBody>
            </Card>
            <Card>
              <CardImg width="50" height="50" src={bulletinIcon} alt="Total number of Bulletins" />
              <CardBody>
                <CardTitle>{dashboardData[0] && dashboardData[0].total_bulletin_count}</CardTitle>
                <CardText>Total number of Bulletins</CardText>
                <Button className="more-detail" onClick={() => this.props.history.push('/bulletin-board')}>
                  {more_details}
                </Button>
              </CardBody>
            </Card>
          </div>
        )}
        <div className="graph-wrap">
          <div className="total-revenue">
            <div className="left">
              <span className="title">Users and Revenue Trends</span>
            </div>
            <div className="right">
              <FormGroup>
                <Input disabled={loadedGraph} type="select" name="selectedValue" onChange={this.handleChange} value={this.state.selectedValue}>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                  <option value="custom">Custom</option>
                </Input>
              </FormGroup>
            </div>
            {
              this.state.selectedValue === 'custom' && (
                <div className="right d-flex">
                  <FormGroup>
                    <Input type="date" name="start_date" value={this.state.start_date} onChange={this.handleChange} />
                  </FormGroup>
                  <FormGroup>
                    <Input type="date" name="end_date" value={this.state.end_date} onChange={this.handleChange} />
                  </FormGroup>
                </div>
              )
            }
          </div>
          <div className="graph-box">
            {
              loadedGraph 
                ? <div className='w-100 d-flex justify-content-center align-items-center' style={{ width: 500, height: 300 }}>Loading...</div>
                : <LineChart chartData={chartData} />
            }
          </div>
          <div className="revenue-wrap">
            <div className="headlineplan">
              <span className="title">Total Revenue</span>
              <strong className="revenue">
                <sup>$</sup>
                { loadedGraph ? 0 : membershipRevenue}
              </strong>
            </div>
            <div className="headlineplan">
              <span className="title">Total Users</span>
              <strong className="revenue">
                { loadedGraph ? 0 : totalUsers}
              </strong>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  dashboardStatics,
  dashboardGraphData
})(withRouter(Dashboard));
