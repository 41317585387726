import React, { Fragment } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import profileIcon from "../../../assets/images/user.jpg";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import ReactCrop from "react-image-crop";
import ModalBox from "../../../components/shared/pageComponent/ModalBox";
import { getUsersTalents, getUsersGenre } from "../../../api/users";
import { userUpdate, userDetail, getAwsCredentials } from "../../../api/admin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Numbers, Text } from "../../../constants/constant";
import S3FileUpload from "react-s3";
import LocationSearchInput from "./LocationSearchInput";
class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      email: "",
      bio: "",
      locationLabel: "",
      website_links: null,
      urls: null,
      video_links: [],
      selectedOption: null,
      src: null,
      croppedImageUrl: null,
      isModalOpen: false,
      place: null,
      genre: [],
      talents: [],
      select_genre: [],
      select_talents: [],
      userDetails: {},
      location: null,
      state: null,
      city: null,
      latitude: null,
      longitude: null,
      address: [],
      zipcode: "",
      profile_image: null,
      awsCredentials: null,
      crop: {
        unit: "%",
        width: Numbers.thrt,
        aspect: Numbers.one,
      },
      is_address: true,
      onLocationLoad: false,
    };
  }

  async componentDidMount() {
    this.props.setLoading(true);
    this.props.setTitlePage("User Management");
    const Id = this.props.location.pathname.split("/edit-profile/");
    this.setState({ userId: Id[1] });
    const userData = await this.props.userDetail(Id[1]);
    const awsCredentials = await this.props.getAwsCredentials();
    if (userData && userData.data) {
      this.setState({
        awsCredentials,
        userDetails: userData,
        email: userData.data.email,
        fullname: userData.data.name,
        bio: userData.data.bio,
        location: userData.data.city,
      });
      let genre = [];
      let roles = [];
      userData.data.genres.length &&
        userData.data.genres.forEach((element) => {
          genre.push({ value: element.id, label: element.title });
        });
      userData.data.roles.length &&
        userData.data.roles.forEach((element) => {
          roles.push({ value: element.id, label: element.title });
        });
      this.setState({
        website_links: userData.data.website_url,
        video_links:
          userData.data.video_links === null ? [] : userData.data.video_links,
        profile_image: userData.data.profile_image,
      });
      if (userData.data.latitude) {
        this.setState({
          locationLabel: `${userData.data.city}, ${userData.data.state}`,
          latitude: userData.data.latitude,
          longitude: userData.data.longitude,
        });
      }
      this.setState({
        croppedImageUrl: userData.data.profile_image,
        select_genre: genre,
        select_talents: roles,
        city: userData.data.city,
        state: userData.data.state,
      });
      await this.getTalent();
      await this.getGenre();
    } else {
      this.props.setLoading(false);
    }
    this.setState({ onLocationLoad: true });
  }

  getTalent = async () => {
    const data = await this.props.getUsersTalents();
    this.props.setLoading(false);
    if (data) {
      let tal = [];
      data.forEach((element) => {
        tal.push({ value: element.id, label: element.title });
      });
      this.setState({ talents: tal });
    }
  };

  getGenre = async () => {
    const data = await this.props.getUsersGenre();
    this.props.setLoading(false);
    if (data && data.length) {
      let gen = [];
      data.forEach((element) => {
        gen.push({ value: element.id, label: element.title });
      });
      this.setState({ genre: gen });
    }
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > Numbers.zero) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, isModalOpen: true })
      );
      reader.readAsDataURL(e.target.files[Numbers.zero]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      Numbers.zero,
      Numbers.zero,
      crop.width,
      crop.height
    );

    let $this = this;
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        $this.setState({
          blob: blob,
        });
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  handleAction = (action) => {
    if (action === "cancel") {
      this.setState({ croppedImageUrl: this.state.profile_image });
      this.imageRef = "";
    }
    this.setState({ isModalOpen: false });
  };

  validateData = (talentsIds, genreIds) => {
    if (talentsIds.length === 0) {
      document
        .getElementsByClassName(Text.formControl)[0]
        .classList.add("error");
    } else {
      document
        .getElementsByClassName(Text.formControl)[0]
        .classList.remove("error");
    }
    if (genreIds.length === 0) {
      document
        .getElementsByClassName(Text.formControl)[1]
        .classList.add("error");
    } else {
      document
        .getElementsByClassName(Text.formControl)[1]
        .classList.remove("error");
    }
  };

  videoLinks = (data) => {
    if (typeof data.video_links === "object") {
      return data.video_links;
    } else if (data.video_links !== "") {
      return [data.video_links];
    }
    return [];
  };

  createData = async (data) => {
    return {
      name: data.fullname,
      bio: data.bio,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      latitude: data.latitude,
      longitude: data.longitude,
      profile_image:
        data.profile_image && data.profile_image.includes("https://")
          ? data.profile_image.split("s3.amazonaws.com")[1]
          : data.profile_image,
      genreIds: data.genreIds,
      roleIds: data.talentsIds,
      website_url: data.website_links,
      video_links: await this.videoLinks(data),
    };
  };

  validateWebsiteUrl(url) {
    var isValidUrl = false;
    if (url.length > 0) {
      var p =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
      if (url.match(p)) {
        isValidUrl = true;
      }
    }
    return isValidUrl;
  }

  validateYoutubeUrl(url) {
    const p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return url.map((res) => {
      if (!res) return true;
      return res.match(p) ? true : false;
    });
  }
  validateVimeoUrl(url) {
    const p = /(?:https?:\/\/(?:www\.)?)?vimeo.com\/(?:channels\/|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
    return url.map((res) => {
      if (!res) return true;
      return res.match(p) ? true : false;
    });
  }

  updateUser = async () => {
    const {
      fullname,
      bio,
      city,
      state,
      zipcode,
      latitude,
      longitude,
      profile_image,
      video_links,
      website_links,
      select_talents,
      select_genre,
      userId,
      is_address,
    } = this.state;
    let talentsIds = [];
    let genreIds = [];
    if (!is_address) {
      toast("Location is required!", { type: "error" });
      return;
    }
    this.props.setLoading(true);
    if (website_links) {
      const isValid = this.validateWebsiteUrl(website_links);
      if (isValid === false) {
        toast("Website url is not correct!", { type: "error" });
        this.props.setLoading(false);
        return false;
      }
    }
    const filteredEmptyString =
      video_links.length && video_links.filter(Boolean);
    if (filteredEmptyString.length > 0) {
      const isValid =
        this.validateYoutubeUrl(video_links) ||
        this.validateVimeoUrl(video_links);
      const getFalse = isValid.includes(false);
      if (getFalse) {
        toast("Video url is not correct!", { type: "error" });
        this.props.setLoading(false);
        return false;
      }
    }

    if (!latitude) {
      toast("Location is required!", { type: "error" });
      this.props.setLoading(false);
      return;
    }
    if (select_talents && select_talents.length) {
      select_talents.forEach((element) => {
        talentsIds.push(element.value);
      });
    }
    if (select_genre && select_genre.length) {
      select_genre.forEach((element) => {
        genreIds.push(element.value);
      });
    }
    await this.validateData(talentsIds, genreIds);
    const updated_video_links = video_links.filter((item) => item !== "");
    const params = {
      fullname,
      bio,
      city,
      state,
      zipcode,
      latitude,
      longitude,
      profile_image,
      genreIds,
      talentsIds,
      video_links: updated_video_links,
      website_links,
    };
    let data = await this.createData(params);
    let userApi = await this.props.userUpdate(userId, data);
    if (userApi && userApi.status === 200) {
      toast(userApi.data && userApi.data.msg);
      this.props.history.goBack();
    } else {
      toast(userApi && userApi.data && userApi.data.msg, { type: "error" });
    }
    this.setState({ loaded: false });
    this.props.setLoading(false);
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
    if (e.target.value === "") {
      e.target.classList.add("error");
    } else {
      e.target.classList.remove("error");
    }
  };

  selectTalents = (selections) => {
    this.setState({ select_talents: selections });
  };

  selectGenre = (selections) => {
    this.setState({ select_genre: selections });
  };

  navigateToDetails = () => {
    this.props.history.push(`/user-detail/${this.state.userId}`);
  };

  uploadImageOns3 = () => {
    this.props.setLoading(true);
    let fileName = `BassParlour_${new Date().getTime()}_${this.fileUrl.substring(
      this.fileUrl.lastIndexOf("/") + 1
    )}.jpg`;
    const userId = this.props.match.params.userId;
    const folder = `users/${userId}/profile`;
    const { bucket_name, aws_access_key_id, aws_secret_access_key } =
      this.state.awsCredentials.s3;
    const config = {
      bucketName: bucket_name,
      dirName: folder,
      region: "us-east-1",
      accessKeyId: aws_access_key_id,
      secretAccessKey: aws_secret_access_key,
    };
    let fileObj = this.state.blob;
    fileObj.name = fileName;
    this.setState({ isModalOpen: false });
    S3FileUpload.uploadFile(fileObj, config)
      .then((data) => {
        this.setState({
          profile_image: `/${data.key}`,
        });
        this.props.setLoading(false);
      })
      .catch((error) => error);
  };

  selectedLocation = (val, item) => {
    if (item) {
      const locationData = val.split(",");
      this.setState({
        locationLabel: `${locationData[0]}, ${locationData[1]}`,
        latitude: item.lat,
        longitude: item.lng,
        city: locationData[0],
        state: locationData[1],
      });
    }
  };
  handleVideoUrlListChange = (index, event) => {
    var videos = this.state.video_links.slice();
    videos[index] = event.target.value;
    this.setState({ video_links: videos });
  };
  handleVideoUrlChange = (event) => {
    this.setState({
      video_links: [...this.state.video_links, event.target.value],
    });
  };
  removeUrl = (index, event) => {
    var videos = this.state.video_links.slice();
    videos[index] = "";
    this.setState({ video_links: videos });
  };

  isAddress = (data) => {
    if (data) {
      this.setState({ is_address: true });
    } else {
      this.setState({ is_address: false });
    }
  };

  render() {
    const {
      fullname,
      bio,
      email,
      website_links,
      video_links,
      src,
      crop,
      croppedImageUrl,
      talents,
      genre,
      select_genre,
      select_talents,
      locationLabel,
      onLocationLoad,
      userId,
    } = this.state;
    return (
      <>
        <div className="header-section">
          <div className="title-wrap">
            <h3>User Details</h3>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/user-list">User Listings</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/user-detail/${userId}`}>User Details</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Edit Profile</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div></div>
        <ModalBox
          isOpen={this.state.isModalOpen}
          withHeader={true}
          ModalTitle="Upload Image"
          toggle={this.toggleModal}
          className="modal-crop"
        >
          {src && (
            <Fragment>
              {" "}
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              <div className="action-wrap">
                <Button
                  color="secondary"
                  onClick={() => this.handleAction("cancel")}
                >
                  Cancel
                </Button>
                <Button color="primary" onClick={this.uploadImageOns3}>
                  Ok
                </Button>
              </div>
            </Fragment>
          )}
        </ModalBox>
        <div className="white-bg-row wide">
          <Form className="profile-form">
            <FormGroup className="file-field">
              <figure className="pofile-pic">
                <img
                  src={croppedImageUrl ? croppedImageUrl : profileIcon}
                  alt="profile icons"
                  width="80"
                  height="80"
                />
              </figure>
              <span className="edit-pic">
                <Input
                  type="file"
                  name="file"
                  id="exampleFile"
                  onChange={this.onSelectFile}
                />
              </span>
            </FormGroup>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">Full Name</Label>
                  <Input
                    type="text"
                    name="fullname"
                    id="fullname"
                    onChange={this.handleChange}
                    value={fullname}
                    placeholder="Enter Full Name"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="userEmail"
                    value={email}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col xs={12}>
                <FormGroup>
                  <label htmlFor="bio">Bio</label>
                  <Input
                    id="bio"
                    name="bio"
                    style={{ height: 100 }}
                    maxLength="1000"
                    type="textarea"
                    onChange={this.handleChange}
                    value={bio}
                    placeholder="Enter Bio"
                  />
                </FormGroup>
              </Col>
            </Row>
            <strong className="form-title">Location</strong>
            <Row form>
              <Col xs={6}>
                <FormGroup className="location">
                  <Label for="exampleCity">Location</Label>
                  {onLocationLoad && (
                    <LocationSearchInput
                      selectedLocation={(data, getLatLngs) =>
                        this.selectedLocation(data, getLatLngs)
                      }
                      ApiAddress={locationLabel}
                      isAddress={(data) => this.isAddress(data)}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <strong className="form-title">Interest</strong>
            <Row form>
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleCity">Music Talent</Label>
                  <Select
                    value={select_talents}
                    onChange={this.selectTalents}
                    options={talents}
                    isMulti
                    className={Text.formControl}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleState">Music Genre</Label>
                  <Select
                    value={select_genre}
                    onChange={this.selectGenre}
                    options={genre}
                    isMulti
                    className={Text.formControl}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="exampleZip">Website</Label>
                  <Input
                    type="text"
                    name="website_links"
                    id="Website"
                    onChange={this.handleChange}
                    value={website_links}
                    placeholder="Enter Website"
                  />
                </FormGroup>
              </Col>
            </Row>

            <strong className="form-title">Youtube & Vimeo URL</strong>
            <Label for="exampleAddress">URL</Label>
            {video_links && video_links.length ? (
              video_links.map((val, index) => (
                <FormGroup className="video-url" key={index}>
                  <Input
                    type="text"
                    placeholder="Enter video url"
                    onChange={this.handleVideoUrlListChange.bind(this, index)}
                    value={val}
                  />
                  {val ? (
                    <span
                      className="remove"
                      onClick={this.removeUrl.bind(this, index)}
                    >
                      Remove
                    </span>
                  ) : (
                    ""
                  )}
                </FormGroup>
              ))
            ) : (
              <FormGroup className="video-url">
                <Input
                  type="text"
                  name="video_links"
                  id="vidUrl"
                  placeholder="Enter video url"
                  onChange={this.handleVideoUrlChange.bind(this)}
                />
              </FormGroup>
            )}

            <div className="action-wrap">
              <Button color="secondary" onClick={this.navigateToDetails}>
                Cancel
              </Button>
              <Button color="primary" onClick={this.updateUser}>
                Update
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    admin: store.user,
  };
};

export default connect(mapStateToProps, {
  userDetail,
  getUsersTalents,
  getUsersGenre,
  userUpdate,
  getAwsCredentials,
})(withRouter(EditProfile));
