import React from "react";
import * as Solid from "@heroicons/react/24/solid";
import Carousel from "react-elastic-carousel";
import PreloadImage from "react-preload-image";
import "./style.scss";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
  { width: 2050, itemsToShow: 7 },
  { width: 2350, itemsToShow: 8 },
  { width: 2650, itemsToShow: 9 },
  { width: 2950, itemsToShow: 10 },
];

export const Songs = ({
  songs,
  songsSelected,
  select,
  current,
  handleSelectFeatureSong,
  handleCurrent,
}) => {
  return (
    <div className="songs">
      {songs.length === 0 && (
        <div className="empty">Not songs submissions.</div>
      )}
      {songs.length > 0 && (
        <Carousel
          breakPoints={breakPoints}
          pagination={false}
          renderArrow={({ type, onClick, isEdge }) => {
            const pointer =
              type === "PREV" ? (
                <Solid.ChevronLeftIcon />
              ) : (
                <Solid.ChevronRightIcon />
              );
            return (
              <button
                className="arrow-slider"
                onClick={onClick}
                disabled={isEdge}
              >
                {pointer}
              </button>
            );
          }}
        >
          {songs.map((song, index) => {
            const active = index === current;
            const activeTop = songsSelected.includes(song.id);
            return (
              <div key={index} className="card-song">
                {select && (
                  <div
                    className={`check ${activeTop && "active"}`}
                    onClick={() => handleSelectFeatureSong(song.id)}
                  >
                    <Solid.CheckIcon className="check-icon" />
                  </div>
                )}
                <div className={`card-info ${active && "active"}`}>
                  <PreloadImage
                    className="card-img"
                    src={song.media.thumbnail_url}
                    alt={song.media.title}
                    lazy
                  />
                  <h3 className="card-title">{song.media.title}</h3>
                  <button
                    disabled={select}
                    className="play-song"
                    onClick={() => handleCurrent(index)}
                  >
                    {active ? (
                      <Solid.PauseIcon className="icon-play" />
                    ) : (
                      <Solid.PlayIcon className="icon-play" />
                    )}
                  </button>
                </div>
                <div className="list">
                  <h3 className="title">Partners:</h3>
                  {song.users.map((user, index) => {
                    return (
                      <div key={index} className="partner">
                        <div className="cover">
                          {user.profile_image_url ? (
                            <PreloadImage
                              className="image"
                              src={user.profile_image_url}
                              alt={user.name}
                              lazy
                            />
                          ) : (
                            <span className="alias">
                              {user.name.charAt(0).toUpperCase()}
                            </span>
                          )}
                        </div>
                        <div className="info">
                          <h3 className="title">{user.name}</h3>
                          <p className="description">
                            {user.city}-{user.state}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
